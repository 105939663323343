import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfiguracionesModel } from 'app/models/configuraciones.model';
import { ConfiguracionesService } from './../../../../services/configuraciones.service';

@Component({
  selector: 'app-modal-sesion-expirada',
  templateUrl: './modal-sesion-expirada.component.html',
  styleUrls: ['./modal-sesion-expirada.component.scss']
})
export class ModalSesionExpiradaComponent implements OnInit {

    public static totalTime: number;
    configuraciones: ConfiguracionesModel;
    constructor(
        public bsModalRef: BsModalRef,
        private configuracionesService: ConfiguracionesService
        ) {
        this.configuraciones = this.configuracionesService.obtenerVariablesEnMemoria();
        ModalSesionExpiradaComponent.totalTime = this.configuraciones.sesion_tiempo_modal_cerrado * 60;
    }

    ngOnInit(): void {
        const countdown = document.getElementById('countdown');
        if (countdown) {
           document.getElementById('countdown').innerHTML = ModalSesionExpiradaComponent.totalTime.toString();
        }    
        ModalSesionExpiradaComponent.updateClock();
    }

    public continuar() {
        this.bsModalRef.hide();
    }
    public static updateClock() {
        if (document.getElementById('countdown')) {
            document.getElementById('countdown').innerHTML = `${ModalSesionExpiradaComponent.totalTime}`;
        }
        if (ModalSesionExpiradaComponent.totalTime === 0){
            ModalSesionExpiradaComponent.totalTime = 30;
        } else {
            ModalSesionExpiradaComponent.totalTime = ModalSesionExpiradaComponent.totalTime - 1;
            setTimeout(ModalSesionExpiradaComponent.updateClock,1000);
        }
    }

}
