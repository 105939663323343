import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { RespuestaApi, RespuestaError } from '../../shared/interfaces/respuesta-api';
import { ToastrService } from 'ngx-toastr';
import { RootService } from '../../services/root.service';
import { ValidatorFn, AbstractControl} from '@angular/forms';
import { UsuarioService } from '../../services/usuario.service';
import { mensajes } from '../../data/mensajes';
import { LocalStorageService } from 'angular-2-local-storage';
import { Usuario } from '../../shared/interfaces/usuario';
import { CanalesService } from '../../services/canales.service';
import { DetalleCaja } from '../../shared/interfaces/caja';
import { CajasUsuarioService } from '../../services/cajas-usuario.service';
import { ConfiguracionesService } from '../../services/configuraciones.service';

function equalsValidator(otherControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const value: any = control.value;
    const otherValue: any = otherControl.value;
    return otherValue === value ? null : { 'notEquals': { value, otherValue } };
  };
}

export const CustomValidators = {
  equals: equalsValidator
};
@Component({
  selector: 'app-login-recuperacion',
  templateUrl: './login-recuperacion.component.html',
  styleUrls: ['./login-recuperacion.component.scss']
})
export class LoginRecuperacionComponent implements OnInit {

	public cargando = false;
    formRecuperar: FormGroup;
    recuperandoClave = false;
    private usuario_id = '';
    private codigo = '';
    public usuario: Usuario;
	constructor(
        private formBuilder: FormBuilder,
    	private route: ActivatedRoute,
        private loginService: LoginService,
        private toastr: ToastrService,
        private root: RootService,
        private usuarioService: UsuarioService,
        public localStore: LocalStorageService,
        private canalesService: CanalesService,
        public router: Router,
        private cajasUsuarioService: CajasUsuarioService,
        private configuracionesService: ConfiguracionesService
    ) {
        this.formRecuperar = this.formBuilder.group({
            clave_nueva: ['', Validators.required],
            clave_nueva_repetir: ['', Validators.required],
        });
        this.formRecuperar.get('clave_nueva_repetir').setValidators(
          [CustomValidators.equals(this.formRecuperar.get('clave_nueva')), Validators.required]
        );
    }

	ngOnInit() {
		// cuando existe datos adicionales por la url
	    this.route.queryParams.subscribe((qp) => {
			if (qp.codigo) {
				this.loginService.validarCodigoRecuperacion({...qp}).subscribe((r: RespuestaApi) => {
		            this.cargando = false;
		            if (r.error) {
		                this.toastr.error(r.mensaje, 'Error');
                        this.router.navigateByUrl('/login');
		                return false;
		            }
		            this.usuario_id = r.data.usuario_id;
		            this.codigo = qp.codigo;
		            this.recuperandoClave = true;
		            this.toastr.success(r.mensaje, 'Ahora puede cambiar la clave');

		        }, e => {
		            this.cargando = false;
		            this.root.validaError(e);
		        });
			}
	    });
	}
    public cambiarClave() {

        if (this.formRecuperar.invalid) {
            this.formRecuperar.markAllAsTouched();
            this.toastr.error(mensajes.formularioConErrores, mensajes.tituloError);
            return;
        }
        this.usuarioService.cambiarClaveRecuperacion({...this.formRecuperar.value, id: this.usuario_id, codigo: this.codigo}).subscribe((r: RespuestaApi) => {
	        if (r.error) {
	            this.toastr.error(r.mensaje, 'Error');
	            return;
	        }
			this.usuario = r.data.usuario;
	        this.login({email: this.usuario.email, password: this.formRecuperar.value.clave_nueva});

	        this.toastr.success(r.mensaje, 'Proceso correcto');
	        this.formRecuperar.reset();

        }, (e: RespuestaError) => {
          this.toastr.error(e.error.mensaje, 'Error');
        });
        
    }

    private login(usuarioYClave) {
        this.cargando = true;
        this.loginService.iniciarSesion(usuarioYClave).subscribe((r: RespuestaApi) => {
            this.cargando = false;

            if (r.error) {
                this.toastr.error(r.mensaje, 'Error');
                return false;
            }

            this.usuario = r.data.usuario;
            this.localStore.set('access_token', r.data.access_token);

            // validacion si el usuario tiene modulos
            if (this.usuario.modulos.length === 0) {
                this.toastr.error('El usuario no tiene acceso al sistema', 'Error');
                return;
            }

            this.obtenerVariables();
            this.localStore.set('usuario', this.usuario);
            this.localStore.set('sucursal', this.usuario.sucursal);

            this.canalesService.buscar({ nombre: 'Caja' }).subscribe((canales: RespuestaApi) => {
                this.localStore.set('canal', canales.data[0]);
            });

            this.router.navigate(this._construyeRuta());
            this.root.actualizarUsuario(this.usuario);

            if (r.data.cajasUsuario.length > 0) {
                const detalleCajaAbierta: DetalleCaja = r.data.detalleCaja;
                this.cajasUsuarioService.guardarSesionCaja(detalleCajaAbierta);
            } else {
                this.cajasUsuarioService.guardarSesionCaja(null);
            }


        }, e => {
            this.cargando = false;
            this.root.validaError(e);
        });
    }

    private obtenerVariables() {
        const parametros = { formato: 'agrupado' };
        this.configuracionesService.obtenerVariables(parametros).subscribe((r: RespuestaApi) => {
            if (r.error) {
                this.toastr.error(r.mensaje, 'Error');
                return false;
            }
            this.configuracionesService.guardarVariablesEnMemoria(r.data);
        }, e => {
            this.cargando = false;
            this.root.validaError(e);
        });
    }

    _construyeRuta() {
        return this.usuario.modulos[0].ruta;
    }

}
