import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CanalesService {
    constructor(http) {
        this.http = http;
    }
    buscar(query) {
        return this.http.get(environment.urlApiNew + 'canal', {
            params: query
        });
    }
}
CanalesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CanalesService_Factory() { return new CanalesService(i0.ɵɵinject(i1.HttpClient)); }, token: CanalesService, providedIn: "root" });
