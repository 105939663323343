import { Directive, HostListener } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
LocalStorageService
/**
 * Allows the sidebar to be toggled via click.
 */
@Directive({
    selector: '[appSidebarToggler]',
})
export class SidebarToggleDirective {
    constructor() { }

    @HostListener('click', ['$event'])
    toggleOpen($event: any) {
        $event.preventDefault();
        document.querySelector('body').classList.toggle('sidebar-hidden');
    }
}

@Directive({
    selector: '[appSidebarMinimizer]',
})
export class SidebarMinimizeDirective {
    constructor(private localStorage: LocalStorageService) { }

    @HostListener('click', ['$event'])
    toggleOpen($event: any) {

        if (document.querySelector('body.sidebar-minimized')) {
            this.localStorage.set('barraLateralAbierta', true);
            console.log('abierta');

        } else {
            console.log('cerrada');

            this.localStorage.set('barraLateralAbierta', false);
        }

        $event.preventDefault();
        document.querySelector('body').classList.toggle('sidebar-minimized');
    }
}

@Directive({
    selector: '[appMobileSidebarToggler]',
})
export class MobileSidebarToggleDirective {
    constructor() { }

    // Check if element has class
    private hasClass(target: any, elementClassName: string) {
        return new RegExp('(\\s|^)' + elementClassName + '(\\s|$)').test(
            target.className
        );
    }

    @HostListener('click', ['$event'])
    toggleOpen($event: any) {
        $event.preventDefault();
        document.querySelector('body').classList.toggle('sidebar-mobile-show');
    }
}

@Directive({
    selector: '[appMobileSidebarClose]',
})
export class MobileSidebarCloseDirective {
    constructor() { }

    @HostListener('click', ['$event'])
    toggleOpen($event: any) {
        const element = document.querySelector('body.sidebar-mobile-show');

        if (element) {
            $event.preventDefault();
            element.classList.remove('sidebar-mobile-show');
        }
    }
}

/**
 * Allows the off-canvas sidebar to be closed via click.
 */
@Directive({
    selector: '[appSidebarClose]',
})
export class SidebarOffCanvasCloseDirective {
    constructor() { }

    // Check if element has class
    private hasClass(target: any, elementClassName: string) {
        return new RegExp('(\\s|^)' + elementClassName + '(\\s|$)').test(
            target.className
        );
    }

    // Toggle element class
    private toggleClass(elem: any, elementClassName: string) {
        let newClass = ' ' + elem.className.replace(/[\t\r\n]/g, ' ') + ' ';
        if (this.hasClass(elem, elementClassName)) {
            while (newClass.indexOf(' ' + elementClassName + ' ') >= 0) {
                newClass = newClass.replace(' ' + elementClassName + ' ', ' ');
            }
            elem.className = newClass.replace(/^\s+|\s+$/g, '');
        } else {
            elem.className += ' ' + elementClassName;
        }
    }

    @HostListener('click', ['$event'])
    toggleOpen($event: any) {
        $event.preventDefault();

        if (
            this.hasClass(document.querySelector('body'), 'sidebar-off-canvas')
        ) {
            this.toggleClass(document.querySelector('body'), 'sidebar-opened');
        }
    }
}

export const SIDEBAR_TOGGLE_DIRECTIVES = [
    SidebarToggleDirective,
    SidebarMinimizeDirective,
    SidebarOffCanvasCloseDirective,
    MobileSidebarToggleDirective,
    MobileSidebarCloseDirective,
];
