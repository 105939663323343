import { Component, OnInit, Input, OnDestroy, TemplateRef, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Usuario } from '../../interfaces/usuario';
import { RootService } from '../../../services/root.service';
import { DetalleCaja } from '../../interfaces/caja';
import { CajasUsuarioService } from '../../../services/cajas-usuario.service';
import { ConfiguracionesService } from '../../../services/configuraciones.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfiguracionesModel } from 'app/models/configuraciones.model';


interface Menu {
  nombre: string;
  icono: string;
  /** última sección de la ruta de `url` */
  slug: string;
  activo: boolean;
  url: Array<string>;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  templateClaveModal: TemplateRef<any>;
  modalRefClave: BsModalRef;
  configuraciones: ConfiguracionesModel = {};
  @Output() modalClave = new EventEmitter<any>();

  @Input() set usuario(val: Usuario) {
    this.usuarioData = val;
    this.sucursal = this.usuarioData.sucursal.nombre;
  }

  sucursal: string;
  usuarioData: Usuario;
  detalleCaja: DetalleCaja;
  suscripcion: Subject<void> = new Subject();

  opcionesMenu: Menu[] = [
    {
      nombre: 'Punto de venta',
      icono: 'icon icon-basket-loaded pointer',
      slug: 'punto-de-venta',
      activo: false,
      url: ['/', 'pos', 'punto-de-venta']
    },
    {
      nombre: 'Venta rápida',
      icono: 'fas fa-shopping-basket pointer',
      slug: 'venta-rapida',
      activo: false,
      url: ['/', 'pos', 'venta-rapida']
    },
    {
      nombre: 'Devoluciones',
      icono: 'icon icon-reload pointer',
      slug: 'devoluciones',
      activo: false,
      url: ['/', 'pos', 'devoluciones']
    },
    {
      nombre: 'Pago OT',
      icono: 'icon icon-tag',
      slug: 'orden-transporte',
      activo: false,
      url: ['/', 'pos', 'orden-transporte']
    }
  ];

  operacionesSeleccionada: Menu;
  mostrarMenuOperaciones = false;
  impresoraConectando = false;


  constructor(
    public root: RootService,
    private cajasUsuarioService: CajasUsuarioService,
    private router: Router,
    private configuracionesService: ConfiguracionesService,
    private route: ActivatedRoute,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.cajasUsuarioService.$cajaUsuario.pipe(takeUntil(this.suscripcion)).subscribe(data => {
      this.detalleCaja = data;
    });

    this.configuracionesService.$menuOperacionHeader.pipe(takeUntil(this.suscripcion)).subscribe(r => {
      this.mostrarMenuOperaciones = r;
      if (this.mostrarMenuOperaciones) {
        this.detectarOperacionActualPos();
      }
    });


  }

  ngOnDestroy() {
    this.suscripcion.next();
    this.suscripcion.complete();
  }

  cambiarOperacion(item: Menu) {
    this.activarMenu(item);
    this.router.navigate(item.url);
  }

  detectarOperacionActualPos() {
    const fullUrl = this.router.url;
    const url = fullUrl.split('?')[0];
    const operacion = url.split('/').reverse()[0];
    const itemOperacion = this.opcionesMenu.find(item => item.slug === operacion);
    this.activarMenu(itemOperacion);
  }

  private activarMenu(item: Menu) {
    this.opcionesMenu.map(item => item.activo = false);
    item.activo = true;
    this.operacionesSeleccionada = item;
  }

  abrirConfiguraciones() {
    this.root.barraConfiguracion(true);
    return false;
  }

  estableceModalClave(template) {
    this.templateClaveModal = template;
  }
  public abrirModalCambiarClave() {
    const options: ModalOptions = {
      backdrop: 'static',
      class: 'modal-lg'
    };
    this.modalRefClave = this.modalService.show(this.templateClaveModal, options);
    this.modalClave.emit(this.modalRefClave);
  }
}