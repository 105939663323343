<div class="modal-header align-items-center">
        <h4 class="modal-title pull-left">
            <span>Sesión a punto de terminar </span>
        </h4>
        <button type="button" class="close float-right" aria-label="Close" (click)="continuar()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <p>Su sesion se cerrara en <span id="countdown"></span> segundos</p>
    <div class="modal-footer d-inline-block right">
        <button class="btn btn-primary  mr-2" (click)="continuar()">
            <i class="fa fa-check"></i> Continuar
        </button>
</div>