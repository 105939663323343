import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { BsModalRef, BsModalService } from 'ngx-bootstrap';

import { MsgboxComponent } from './modshared/mod/msgbox/msgbox.component';
import { NotificacionesWsService } from './services/notificaciones-ws.service';
import { Notificaciones } from './shared/interfaces/notificaciones';
import { ConfiguracionesService } from './services/configuraciones.service';
import { RootService } from './services/root.service';
import { ImpresionService } from './services/impresion.service';
import { SesionService } from './services/sesion.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  @ViewChild(MsgboxComponent, { static: false }) message: MsgboxComponent;

  templateModal: TemplateRef<any>;
  modalRef: BsModalRef;
  private toasterSubscription: Subscription;
  dataNotificaciones: Notificaciones;

  constructor(
    private notificacionesWsService: NotificacionesWsService,
    private modalService: BsModalService,
    private configuracionesService: ConfiguracionesService,
    private root: RootService,
    private sesion: SesionService,
    private impresionService: ImpresionService
  ) { }

  ngOnInit() {
    this.notificacionesWsService.conexionWs();

    this.notificacionesWsService.$notificaciones.subscribe((data: Notificaciones) => {
      if (data) {
        this.modalRef = this.modalService.show(this.templateModal);
        this.dataNotificaciones = data;
      }
    });

    this.configuracionesService.actualizarVariablesMemoria();
    // this.impresionService.obtenerConfiguracion();
    this.impresionService.impresoraDisponibles();

  }

  establecerModalInformacion(template) {
    this.root.establecerModalTemplate(template);
  }

  conexionCanalNotificaciones() {
  }



}