/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./msgbox.component";
var styles_MsgboxComponent = [];
var RenderType_MsgboxComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MsgboxComponent, data: {} });
export { RenderType_MsgboxComponent as RenderType_MsgboxComponent };
export function View_MsgboxComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_MsgboxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-msgbox", [], null, null, null, View_MsgboxComponent_0, RenderType_MsgboxComponent)), i0.ɵdid(1, 114688, null, 0, i1.MsgboxComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MsgboxComponentNgFactory = i0.ɵccf("app-msgbox", i1.MsgboxComponent, View_MsgboxComponent_Host_0, { Mensaje: "Mensaje" }, {}, []);
export { MsgboxComponentNgFactory as MsgboxComponentNgFactory };
