<ng-template #modalTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{titulo}}</h4>
        <button type="button" class="close float-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" (keyup.enter)="guardar()">
        <form [formGroup]="form" class="form-bold">

            <div class="form-group ">
                <label class=" form-control-label">Nombres</label>
                <input type="text" required="true" formControlName="nombres" class="form-control " placeholder="">
            </div>

            <div class="form-group ">
                <label class=" form-control-label">Apellidos</label>
                <input type="text" required="true" formControlName="apellidos" class="form-control " placeholder="">
            </div>

            <div class="form-group ">
                <label class=" form-control-label">Email</label>
                <input type="text" required="true" formControlName="email" class="form-control " placeholder="">
            </div>

            <div class="btn-mostrar" *ngIf="titulo === 'Editar usuario'">
                <i title="Cambiar contraseña" [ngClass]="{'fa-chevron-down': !mostrarFiltros, 'fa-chevron-up': mostrarFiltros}"
                    class="fas  pointer" (click)="cambiarContrasena()" aria-hidden="true"></i>
                    Cambiar contraseña
            </div>

            <div class="form-group "  [collapse]="!mostrarFiltros" >
                <label class=" form-control-label">Contraseña</label>
                <input type="password" required="true" formControlName="contrasena" class="form-control " placeholder="">
            </div>

            <div class="form-group "  [collapse]="!mostrarFiltros" >
                <label class=" form-control-label">Confirmar contraseña</label>
                <input type="password" required="true" formControlName="contrasena_confirm" class="form-control " placeholder="">
            </div>

            <div class="form-group ">
                <label>Perfil</label>
                <ng-select bindLabel="nombre" bindValue="id" [items]="selectPerfiles"
                    formControlName="perfile_id" placeholder="Seleccione..." [searchable]="true">
                </ng-select>
            </div>

            <div class="btn-mostrar">
                <i title="Cambiar Activo" [ngClass]="{'fa-toggle-off': !mostrarActivo, 'fa-toggle-on': mostrarActivo}"
                    class="fas  pointer" (click)="cambiarActivo()" aria-hidden="true"></i>
                    Activo
                <input type="text" hidden="true" formControlName="activo" class="form-control " placeholder="">    
            </div>
        </form>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="modalRef.hide()"> <i class="fa fa-ban"></i>
            Cancelar</button>
        <button type="button" class="btn btn-primary" [disabled]="!editarValido()||form.invalid" (click)="guardar()"> <i
                class="fa fa-save"></i>
            Guardar</button>
    </div>
</ng-template>