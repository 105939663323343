<div class='app-loading' [hidden]="!cargando">
    <!-- <div class='loading-content'>
      <div class='loading-ico no-border'></div>
      <span>Loading...</span>
    </div> -->

    <div class='loading-content'>
        <img [src]="this.configuraciones?.logo_loading">
      <div class='loading-ico'></div>
      <span>Cargando...</span>
    </div>

    <!-- <div class='load-bar'>
      <div class='h-load-bar'>
        <div class='load-step'></div>
      </div>
      <span>Loading...</span>
    </div> -->

  </div>
  