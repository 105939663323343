import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-modal-eliminar',
  templateUrl: './modal-eliminar.component.html',
  styleUrls: ['./modal-eliminar.component.scss']
})
export class ModalEliminarComponent implements OnInit {

  public defaultOption: any
  public options: any

  @Input() parametros: any;

  @ViewChild('ModalEliminar', { static: false }) mdlEliminar: ElementRef
  ModalEliminar;

  @ViewChild('ModalConfirmar', { static: false }) modalConfirmar;
  constructor() { }

  ngOnInit() {

    this.defaultOption = {
      modalClass: '',
      modalType: 'normal',
      modalTitle: 'Titulo',
      modalMsg: 'Mensaje',
      modalFunctionAceptar: () => { this.functionDefault(); }
    };
    this.options = _.assign(this.defaultOption, this.parametros)


  }

  // PERMITE MOSTRAR EL MODAL
  show() {
    this.options = _.assign(this.defaultOption, this.parametros)
    if (this.options.modalType === 'eliminar') {
      // tslint:disable-next-line:max-line-length
      this.options.modalMsg = (this.options.modalMsg === 'Mensaje') ? '¿Esta seguro que desea eliminar el registro?.' : this.options.modalMsg
      this.options.modalTitle = (this.options.modalTitle === 'Titulo') ? 'Eliminar' : this.options.modalTitle
      this.ModalEliminar = this.mdlEliminar;
      this.ModalEliminar.show();

    }

  }

  showConfirm() {

    console.log(this.options.modalMsg)
    this.options = _.assign(this.defaultOption, this.parametros)
    this.modalConfirmar.show();
  }

  functionDefault() {
    alert('ha presionado ok');
  }

  public ejecutarFuncion() {
    this.options.modalFunctionAceptar.call();
  }



}