import * as tslib_1 from "tslib";
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { mensajes } from 'app/data/mensajes';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./configuraciones.service";
import * as i3 from "./root.service";
import * as i4 from "angular-2-local-storage";
import * as i5 from "ngx-toastr";
import * as i6 from "./impresora.service";
export class ImpresionService {
    constructor(http, configuracionesService, root, localStorageService, toastr, impresoraService) {
        this.http = http;
        this.configuracionesService = configuracionesService;
        this.root = root;
        this.localStorageService = localStorageService;
        this.toastr = toastr;
        this.impresoraService = impresoraService;
        this.headers = new HttpHeaders();
        this.impresorasDisponibles = new BehaviorSubject([]);
        this.$impresorasDisponibles = this.impresorasDisponibles.asObservable();
        this.impresoraSeleccionada = null;
        this.headers.set('content-type', 'application/json');
        this.headers.set('Access-Control-Allow-Origin', '*');
        this.configuraciones = this.configuracionesService.obtenerVariablesEnMemoria();
    }
    imprimirComprobante(data) {
        return this.http.post(environment.urlApiImpresion + `imprimir`, data, { headers: this.headers });
    }
    impresionRaw(impresora_ip, impresora_puerto, data) {
        return this.http.post(environment.urlApiNew + `impresora/impresion-raw`, {
            impresora_ip: impresora_ip,
            impresora_puerto: impresora_puerto,
            data: data
        });
    }
    configuracion() {
        return this.http.get(environment.urlApiNew + `impresora/configuracion`);
    }
    obtenerConfiguracion() {
        this.configuracion().subscribe((r) => {
            this.localStorageService.set('configuracionImpresora', r.data);
        });
    }
    listarImpresoras() {
        return this.http.get(environment.urlApiNew + `impresora`);
    }
    impresoraDisponibles() {
        this.listarImpresoras().subscribe((r) => {
            this.impresorasDisponibles.next(r.data);
        }, e => {
            this.root.validaError(e);
        });
    }
    imprimirData(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(data);
            this.impresoraSeleccionada = this.validarMetodoImpresion();
            if (!this.impresoraSeleccionada) {
                return false;
            }
            this.impresoraSeleccionada = this.configuracionLocal.impresoraSeleccionada;
            switch (this.impresoraSeleccionada.slug_tipo_impresora) {
                case 'epson-raw':
                    this.impresionRaw(this.impresoraSeleccionada.ip, this.impresoraSeleccionada.puerto, data).subscribe(r => {
                        console.log(r);
                    }, e => {
                        this.root.validaErrorImpresora(e);
                    });
                    break;
                case 'epson-epos':
                    this.impresoraService.conectarImpresora(this.impresoraSeleccionada.ip, this.impresoraSeleccionada.puerto, data);
                    break;
                case 'api-impresion':
                    this.imprimirComprobante(data).subscribe(r => {
                    }, e => {
                        this.root.validaErrorImpresora(e);
                    });
                    break;
                default:
                    this.toastr.warning('Método de impresión no configurado');
                    break;
            }
        });
    }
    validarMetodoImpresion() {
        this.configuracionLocal = this.root.obtenerConfiguracionesLocales();
        console.log("validarMetodoImpresion() - configuracionLocal", this.configuracionLocal);
        if (!this.configuracionLocal.hasOwnProperty('impresoraSeleccionada')) {
            this.toastr.info(mensajes.confImpresoraPorDefecto, mensajes.tituloInformacion, { positionClass: 'toast-top-center' });
            this.root.barraConfiguracion(true);
        }
        const impresoraSeleccionada = this.configuracionLocal.impresoraSeleccionada;
        return impresoraSeleccionada;
    }
}
ImpresionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImpresionService_Factory() { return new ImpresionService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfiguracionesService), i0.ɵɵinject(i3.RootService), i0.ɵɵinject(i4.LocalStorageService), i0.ɵɵinject(i5.ToastrService), i0.ɵɵinject(i6.ImpresoraService)); }, token: ImpresionService, providedIn: "root" });
