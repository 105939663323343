import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LocalStorageService } from 'angular-2-local-storage';
import { DetalleCaja } from '../shared/interfaces/caja';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CajasUsuarioService {

  // totales
  private cajaUsuarioSubject = new BehaviorSubject<any>('');
  public $cajaUsuario = this.cajaUsuarioSubject.asObservable();

  constructor(private http: HttpClient, private localStorage: LocalStorageService) {
    const detalleCaja = this.obtenerSesionCaja();
    this.cajaUsuarioSubject.next(detalleCaja);
  }

  crear(data) {
    return this.http.post(environment.urlApiNew + 'cajas-usuario', data);
  }

  guardarSesionCaja(data: DetalleCaja) {
    this.localStorage.set('caja', data);
    this.cajaUsuarioSubject.next(data);
  }

  obtenerSesionCaja(): DetalleCaja {
    return this.localStorage.get('caja');
  }

  limpiarSesionCaja() {
    this.cajaUsuarioSubject.next(null);
    return this.localStorage.remove('caja');
  }


  obtenerInformacionCierre(id) {
    return this.http.get(environment.urlApiNew + `cajas-usuario/informacion-cierre/${id}`);
  }


  validarCierreCaja(cajaUsuarioId, data) {
    return this.http.post(`${environment.urlApiNew}cajas-usuario/validar-cierre/${cajaUsuarioId}`, data);
  }

  cerrarCaja(cajaUsuarioId, data) {
    return this.http.post(`${environment.urlApiNew}cajas-usuario/cerrar-caja/${cajaUsuarioId}`, data);
  }

  resumenCierre(cajaUsuarioId) {
    return this.http.get(`${environment.urlApiNew}cajas-usuario/resumen-cierre/${cajaUsuarioId}`);
  }

  // resumenFormaPago(cajaUsuarioId: string, codigo: string) {
  //   return this.http.get(environment.urlApiNew + `cajas-usuario/resumen-forma-pago/${cajaUsuarioId}/${codigo}`);
  // }


}
