import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UsuarioService {
    constructor(http) {
        this.http = http;
    }
    editarClave(data) {
        return this.http.post(environment.urlApiNew + 'login/cambiar-clave', data);
    }
    cambiarClaveRecuperacion(data) {
        return this.http.post(environment.urlApiNew + 'login/cambiar-clave-recuperacion', data);
    }
    obtenerListado(params) {
        return this.http.get(environment.urlApiNew + 'usuario/listado', { params });
    }
    obtenerUsuario(id) {
        return this.http.get(environment.urlApiNew + `usuario/${id}`);
    }
    obtenerPerfiles(params) {
        return this.http.post(environment.urlApiNew + 'usuario/perfiles', { params });
    }
    guardarUsuario(params) {
        return this.http.post(environment.urlApiNew + 'usuario/guardar', { params });
    }
    editarUsuario(params) {
        return this.http.post(environment.urlApiNew + 'usuario/editar', { params });
    }
}
UsuarioService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsuarioService_Factory() { return new UsuarioService(i0.ɵɵinject(i1.HttpClient)); }, token: UsuarioService, providedIn: "root" });
