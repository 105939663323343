<div class="sidebar">
  <nav class="sidebar-nav">

    <div class="d-flex justify-content-center mt-4 mb-4 container-logo">
      <img *ngIf="configuraciones?.logo_header && 
      configuraciones?.logo_header!=='null'"
        [src]="configuraciones?.logo_header" />
    </div>


    <ul class="nav">
      <li *ngFor="let item of menu" class="nav-item">
        <!-- {{item | json}} -->
        <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }"
          [routerLink]="item.ruta">
          <i [class]="item.icono"></i> {{ item.nombre }}</a>
      </li>
    </ul>

  </nav>
  <!-- 

  <ul class="nav close-sesion">
    <li  class="nav-item">
      <a  class="nav-link"  (click)="rootService.logout()">
        <i class="fa fa-sign-out" aria-hidden="true"></i> 
        Cerrar sesión</a>
    </li>
  </ul> -->

</div>
