<app-animacion-cargando [cargando]="cargando"></app-animacion-cargando>
<div class="app flex-row align-items-center container-login">
    <div class="container">
        <!-- Notificaciones -->
        <app-msgbox> </app-msgbox>

        <div class="row justify-content-center">
            <div class="col-lg-8 col-12">
                <div class="card-group mb-0">
                    <div class="card p-4 card-shadow">
                        <div class="card-block" (keyup.enter)="login()">

                            <div class="flex d-none d-sm-block">
                                <div class="login-text-div">
                                    <h2>MOUNTAIN POS</h2>
                                    <p class="text-muted">
                                        Ingrese los datos de su cuenta
                                    </p>
                                </div>

                                <div class="login-img-div">
                                    <img src="assets/img/logoIngydev2.png" />
                                </div>
                            </div>

                            <div class="row d-none d-block d-sm-none text-center">

                                <div class="col-8 offset-2 mb-2">
                                    <img class="img-fluid" src="assets/img/logoIngydev2.png" />
                                </div>

                                <div class="login-text-div">
                                    <h2>MOUNTAIN POS</h2>
                                    <p class="text-muted">
                                        Ingrese los datos de su cuenta
                                    </p>
                                </div>


                            </div>

                            <form [formGroup]="formLogin" *ngIf="!recuperandoClave">
                                <div class="input-group mb-3">
                                    <span class="input-group-addon">
                                        <i class="icon-user"></i> <span>Usuario</span>
                                    </span>
                                    <input type="email" class="form-control" placeholder="Email"
                                        formControlName="email" />
                                </div>

                                <div class="input-group mb-4">
                                    <span class="input-group-addon">
                                        <i class="icon-lock"></i> <span>Contraseña</span> </span>
                                    <input type="password" class="form-control" placeholder="Password"
                                        formControlName="password" />
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                            <a href="#" class="link" (click)="recuperarClave(false)"
                                            *ngIf="!recuperandoClave">
                                            <i class="icon icon-info"></i> Recuperar contraseña
                                        </a>

                                        <button [disabled]="formLogin.invalid"
                                            class="btn btn-primary btn-ladda float-right" data-style="expand-left"
                                            (click)="login()">
                                            <i class="fa fa-sign-in" aria-hidden="true"></i>
                                            Ingresar
                                        </button>
                                    </div>
                                </div>

                            </form>
                            <form [formGroup]="formRecuperar" *ngIf="recuperandoClave">
                                <div class="input-group mb-3">
                                    <span class="input-group-addon">
                                        <i class="icon-user"></i> <span>Usuario</span>
                                    </span>
                                    <input type="text" class="form-control" placeholder="Email"
                                        formControlName="email" />
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        
                                        <button class="btn btn-outline-primary btn-ladda " data-style="expand-left"
                                            (click)="recuperandoClave= !recuperandoClave">
                                            <i class="fa fa-ban" aria-hidden="true"></i>
                                            Cancelar
                                        </button>

                                        <button [disabled]="formRecuperar.invalid"
                                            class="btn btn-primary btn-ladda float-right" data-style="expand-left"
                                            (click)="recuperarClave(true)" *ngIf="recuperandoClave">
                                            <i class="fa fa-sign-in" aria-hidden="true"></i>
                                            Recuperar contraseña
                                        </button>
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>