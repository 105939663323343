import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalSesionExpiradaComponent } from '../shared/components/modals/modal-sesion-expirada/modal-sesion-expirada.component';
import { RootService } from './root.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { AccessToken } from '../shared/interfaces/access-token';
import { HttpClient, HttpXhrBackend, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { RespuestaApi, RespuestaError } from 'app/shared/interfaces/respuesta-api';
import { ConfiguracionesModel } from 'app/models/configuraciones.model';
import { ConfiguracionesService } from './configuraciones.service';

@Injectable()
export class SesionService {

    bsModalRef: BsModalRef;
    private timeout: number;
    private timeModal: number;
    private timeModalCerrado: number;
    private timeActualizarToken: number;
    public static estadoModal = false;
    configuraciones: ConfiguracionesModel;
    constructor(
        private modalService: BsModalService,
        private root: RootService,
        private localStore: LocalStorageService,
        private http: HttpClient,
        private configuracionesService: ConfiguracionesService
    ) {
        this.configuraciones = this.configuracionesService.obtenerVariablesEnMemoria();
        this.timeModal = this.configuraciones.sesion_tiempo_modal * 60000;
        this.timeModalCerrado = this.configuraciones.sesion_tiempo_modal_cerrado  * 60000;
        this.timeActualizarToken = this.configuraciones.sesion_tiempo_actualizar_token  * 60000;
        this.cargarModal();
        this.cerradoModal();
    }
    private cargarModal() {
        const there = this;
        this.movimientoMouse();

        document.onmousemove = function(){
            there.movimientoMouse();
        }
        setInterval(this.actualizarToken, this.timeActualizarToken);
        
    }
    public abrirModal() {
        const access_token: AccessToken = this.localStore.get('access_token') as AccessToken;
        if (access_token) {
            const there = this;
            if (SesionService.estadoModal === false) {
                SesionService.estadoModal = true;
                this.bsModalRef = this.modalService.show(ModalSesionExpiradaComponent);
            }
            clearTimeout(this.timeout);
            this.timeout = window.setTimeout(function(){
                there.cerrarSesion();
            }, this.timeModalCerrado);
        }
    }
    private cerradoModal() {
        this.modalService.onHide.subscribe((reason: string | any) => {
            SesionService.estadoModal = false;
            ModalSesionExpiradaComponent.totalTime = 30;    
        });
    }
    private movimientoMouse() {
        const there = this;
        if (SesionService.estadoModal === false) {
            clearTimeout(this.timeout);
            this.timeout = window.setTimeout(function(){
                there.abrirModal();
            }, this.timeModal);
        }
    }
    private cerrarSesion() {
        this.modalService.hide(1);
        this.root.logout();
    }
    public actualizarToken() {
        const access_token: AccessToken = JSON.parse(localStorage.getItem('mountainImple.access_token')) as AccessToken;
        if (access_token) {
            SesionService.actualizarTokenApi({refresh_token: access_token.refreshToken}, access_token.token).subscribe((r: RespuestaApi) => {        
                localStorage.setItem("mountainImple.access_token", JSON.stringify(r.data.access_token));
            }, (e: RespuestaError) => {

            });
        }
    }
    public static actualizarTokenApi(data,token) {
        let header = new HttpHeaders({ "Authorization": "Bearer " + token});
        const requestOptions = {  headers: header};                                                                                                                                                                            
        const http: HttpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
        return http.post(environment.urlApiNew + 'login/refrescar-token', data, requestOptions);
    }
}