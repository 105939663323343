import { Perfil } from './perfil';

export class Usuario {
    public id: number;
    public email: string;
    public perfile_id: number;
    public cliente_id: number;
    public password: string;
    public nombre: string;
    public Perfile: Perfil;

    constructor() {
        this.id = 0;
        this.email = '';
        this.nombre = '';
        this.perfile_id = 0;
        this.cliente_id = 0;
        this.password = '';
        this.Perfile = new Perfil();
    }
}
