/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-informacion.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./modal-informacion.component";
var styles_ModalInformacionComponent = [i0.styles];
var RenderType_ModalInformacionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalInformacionComponent, data: {} });
export { RenderType_ModalInformacionComponent as RenderType_ModalInformacionComponent };
function View_ModalInformacionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-outline-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-ban"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Mas tarde"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.actualizar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-save"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Actualizar ahora "]))], null, null); }
function View_ModalInformacionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Informaci\u00F3n"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close float-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "modal-body modal-pagar"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalInformacionComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.dataModal.tipo == "actualizacion"); _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataModal.mensaje; _ck(_v, 6, 0, currVal_0); }); }
export function View_ModalInformacionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { modalTemplate: 0 }), (_l()(), i1.ɵand(0, [[1, 2], ["modalInformacion", 2]], null, 0, null, View_ModalInformacionComponent_1))], null, null); }
export function View_ModalInformacionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-informacion", [], null, null, null, View_ModalInformacionComponent_0, RenderType_ModalInformacionComponent)), i1.ɵdid(1, 4308992, null, 0, i3.ModalInformacionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalInformacionComponentNgFactory = i1.ɵccf("app-modal-informacion", i3.ModalInformacionComponent, View_ModalInformacionComponent_Host_0, { modalRef: "modalRef", dataModal: "dataModal" }, { template: "template" }, []);
export { ModalInformacionComponentNgFactory as ModalInformacionComponentNgFactory };
