import { Component, OnInit, EventEmitter, Output, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UsuarioService } from '../../../../services/usuario.service';
import { ToastrService } from 'ngx-toastr';
import { RespuestaApi, RespuestaError } from 'app/shared/interfaces/respuesta-api';
import { ValidatorFn, AbstractControl} from '@angular/forms';

function equalsValidator(otherControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const value: any = control.value;
    const otherValue: any = otherControl.value;
    return otherValue === value ? null : { 'notEquals': { value, otherValue } };
  };
}

export const CustomValidators = {
  equals: equalsValidator
};

@Component({
  selector: 'app-modal-usuarios',
  templateUrl: './modal-usuarios.component.html',
  styleUrls: ['./modal-usuarios.component.scss']
})
export class ModalUsuariosComponent implements OnInit, AfterViewInit {
  @ViewChild('modalTemplate', { static: false }) modalTemplate: ElementRef;
  @Input() modalRef: BsModalRef;
  @Output() template = new EventEmitter<any>();
  @Output() actualizar = new EventEmitter<any>();
  titulo = '';
  form: FormGroup;

  @Input() set id(value) {

    this.obtenerPeriles();
    if (value == null) {
      this.titulo = 'Editar usuario';
      return;
    }
    if (value === 'add') {
      this.titulo = 'Registar usuario';
      this.mostrarFiltros = true;
      if (typeof this.form !== 'undefined') {
        this.form.reset();
      }
      return;
    }
    this.obtenerUsuario(value);

  }
  public selectPerfiles;
  public mostrarFiltros = true;
  public mostrarActivo = true;
  constructor(
    private fb: FormBuilder,
    private usuarioService: UsuarioService,
    private toastr: ToastrService,
    ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      id: null,
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      email: ['', Validators.required],
      contrasena: ['', Validators.required],
      contrasena_confirm: ['', Validators.required],
      perfile_id: ['', Validators.required],
      activo: ['true']
    });
    this.form.get('contrasena_confirm').setValidators(
      [CustomValidators.equals(this.form.get('contrasena')), Validators.required]
    );

  }

  guardar() {
    if (this.form.invalid) {
      console.warn('falta completar el formulario');
      return;
    }
    console.log('id', this.form.value.id);



    if (this.esCrear()) {
      this.crear();
    } else {
      this.editar();
    }
  }
  public esCrear(){
    return (this.form.value.id === null || this.form.value.id === undefined);
  }
  public editarValido() {
    if (
      this.form.value.nombres
      && this.form.value.apellidos
      && this.form.value.email
      && this.form.value.perfile_id
      && this.form.value.nombres.length > 0
      && this.form.value.apellidos.length > 0
      && this.form.value.email.length > 0
      && this.form.value.perfile_id.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }
  private crear() {
    const data = this.form.value;
    delete data.id;

    this.usuarioService.guardarUsuario(this.form.value).subscribe((r: RespuestaApi) => {
      if (r.error) {
        this.toastr.error(r.mensaje, 'Error');
        return;
      }

      this.modalRef.hide();
      this.toastr.success(r.mensaje, 'Proceso correcto');
      this.actualizar.emit();
      this.form.reset();

    }, (e: RespuestaError) => {
      this.toastr.error(e.error.mensaje, 'Error');
    });
  }

  private editar() {
    this.usuarioService.editarUsuario(this.form.value).subscribe((r: RespuestaApi) => {
      if (r.error) {
        this.toastr.error(r.mensaje, 'Error');
        return;
      }

      this.modalRef.hide();
      this.toastr.success(r.mensaje, 'Proceso correcto');
      this.actualizar.emit();
      this.form.reset();

    }, (e: RespuestaError) => {
      this.toastr.error(e.error.mensaje, 'Error');
    });

  }

  ngAfterViewInit(): void {
    this.template.emit(this.modalTemplate);
  }

  obtenerUsuario(id) {
    this.usuarioService.obtenerUsuario(id).subscribe((r: RespuestaApi) => {

      if (r.error) {
        this.toastr.error(r.mensaje, 'Error');
        return;
      }

      this.form.patchValue(r.data);
      this.mostrarActivo = this.form.value.activo;
    }, e => {
      this.toastr.error('Ha ocurrido un error en la comunicación con el servidor', 'Error');
    });

  }

  obtenerPeriles() {
    this.usuarioService.obtenerPerfiles({ formato: 'simple' }).subscribe((r: RespuestaApi) => {

      if (r.error) {
        this.toastr.error(r.mensaje, 'Error');
        return;
      }

      this.selectPerfiles = r.data;
    }, e => {
      this.toastr.error('Ha ocurrido un error en la comunicación con el servidor', 'Error');
    });
  }
  cambiarContrasena() {
    this.mostrarFiltros = !this.mostrarFiltros;
    if (this.mostrarFiltros) {
      this.form = this.fb.group({
        id: this.form.value.id,
        nombres: [this.form.value.nombres, Validators.required],
        apellidos: [this.form.value.apellidos, Validators.required],
        email: [this.form.value.email, Validators.required],
        contrasena: [this.form.value.contrasena, Validators.required],
        contrasena_confirm: [this.form.value.contrasena_confirm, Validators.required],
        perfile_id: [this.form.value.perfile_id, Validators.required],
        activo: [this.form.value.activo]
      });
      this.form.get('contrasena_confirm').setValidators(
        [CustomValidators.equals(this.form.get('contrasena')), Validators.required]
      );
    } else {
      this.form = this.fb.group({
        id: this.form.value.id,
        nombres: [this.form.value.nombres, Validators.required],
        apellidos: [this.form.value.apellidos, Validators.required],
        email: [this.form.value.email, Validators.required],
        perfile_id: [this.form.value.perfile_id, Validators.required],
        activo: [this.form.value.activo]
      });
    }
  }
  cambiarActivo() {
    this.mostrarActivo = !this.mostrarActivo;
    this.form.value.activo = this.mostrarActivo;
  }
}
