<app-animacion-cargando [cargando]="cargando"></app-animacion-cargando>
<div class="app flex-row align-items-center container-login">
    <div class="container">
        <!-- Notificaciones -->
        <app-msgbox> </app-msgbox>

        <div class="row justify-content-center">
            <div class="col-lg-8 col-12">
                <div class="card-group mb-0">
                    <div class="card p-4 card-shadow">
                        <div class="card-block" >

                            <div class="flex d-none d-sm-block">
                                <div class="login-text-div">
                                    <h2>Pruebas de impresion con la libreria Zebra</h2>
                                    
                                </div>
                            </div>
                            <form>

                                <div class="row">
                                    <div class="col-12">
                                        <button 
                                            class="btn btn-primary btn-ladda float-right" data-style="expand-left"
                                            (click)="verificarConexion()">
                                            <i class="fa fa-sign-in" aria-hidden="true"></i>
                                           Imprimir etiqueta
                                        </button>
                                    </div>
                                </div>

                            </form>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>