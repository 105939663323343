<div class="card shadow  card-configuraciones" [ngClass]="{'menu-activo': menuAbierto}">
    <div class="card-header text-center">
        <span class="pull-left">
            Configuraciones
        </span>

        <button aria-label="Close" class="close float-right" type="button" (click)="cerrar()">
            <span aria-hidden="true">×</span></button>
    </div>
    <div class="card-body form-bold">
        <em>Configuraciones y preferencias adicionales del sistema</em>

        <div class="form-group mt-3">
            <label> Impresora por defecto </label>

            <ng-select bindLabel="nombre" bindValue="id" [(ngModel)]="impresoraSeleccionada" [items]="impresoras" (change)="seleccionarImpresora()"
                placeholder="Impresoras..." [searchable]="false">
            </ng-select>
        </div>

    </div>
</div>


<div class="modal-backdrop show" *ngIf="menuAbierto" (click)="cerrar()">

</div>