import { mensajes } from 'app/data/mensajes';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
export class ImpresoraZebraService {
    constructor(toastr) {
        this.toastr = toastr;
        this.devices = [];
        this.repuestaImpresora = {
            conectada: null,
            accion: 'conexion',
            mensaje: null,
            data: null
        };
        this.impresoraSubject = new BehaviorSubject(this.repuestaImpresora);
        this.$impresoraZebra = this.impresoraSubject.asObservable();
        this._this = this;
    }
    conexion(mostrarMensajes = true) {
        const _this = this;
        BrowserPrint.getDefaultDevice("printer", function (device) {
            console.log(device);
            // console.log(_this.devices);
            _this.selected_device = device;
            // _this.devices.push(device);
            _this.repuestaImpresora.conectada = false;
            _this.repuestaImpresora.data = device;
            // console.log(_this.devices);
            BrowserPrint.getLocalDevices(function (device_list) {
                _this.selected_device = device_list.printer[0];
                console.log(_this.selected_device);
                _this.repuestaImpresora.conectada = true;
                _this.impresoraSubject.next(_this.repuestaImpresora);
            });
        }, e => {
            console.log(e);
            this.repuestaImpresora.conectada = false;
            if (mostrarMensajes) {
                this.toastr.error('Ha ocurrido un error al conectar con la impresora de ticket', mensajes.tituloError);
            }
            this.impresoraSubject.next(this.repuestaImpresora);
        });
    }
    imprimir(string) {
        console.log(string);
        console.log(this.selected_device);
        const _this = this;
        this.selected_device.send(string, undefined, (e) => {
            _this.toastr.error(e, mensajes.tituloError);
        });
    }
}
ImpresoraZebraService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImpresoraZebraService_Factory() { return new ImpresoraZebraService(i0.ɵɵinject(i1.ToastrService)); }, token: ImpresoraZebraService, providedIn: "root" });
