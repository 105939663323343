import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LoginService {
    constructor(http) {
        this.http = http;
    }
    iniciarSesion(data) {
        return this.http.post(environment.urlApiNew + 'login/iniciar-session', data);
    }
    recuperarClave(data) {
        return this.http.post(environment.urlApiNew + 'login/recuperar-clave', data);
    }
    validarCodigoRecuperacion(data) {
        return this.http.post(environment.urlApiNew + 'login/codigo-recuperacion', data);
    }
}
LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.HttpClient)); }, token: LoginService, providedIn: "root" });
