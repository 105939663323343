import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../environments/environment';
import { VariablesAgrupadas, Configuraciones, VariableSubgrupos } from '../shared/interfaces/variables';
import { ConfiguracionesModel } from '../models/configuraciones.model';
import { RespuestaApi } from 'app/shared/interfaces/respuesta-api';
import { RootService } from './root.service';
import { FormGroup } from '@angular/forms';
import { DatosPorDefecto } from 'app/shared/interfaces/datos-por-defecto';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionesService {

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private root: RootService
  ) { }

  private variablesSubject = new BehaviorSubject<any>(null);
  public $variables = this.variablesSubject.asObservable();

  private menuOperacionHeaderSub = new BehaviorSubject<any>(false);
  public $menuOperacionHeader = this.menuOperacionHeaderSub.asObservable();

  nombres() {
    return {
      categoria_uno: 'Categoria uno',
      categoria_dos: 'Categoria dos',
      categoria_tres: 'Categoria tres',
      precio_minimo: 10,
      marca: 'Laboratorio',
    };
  }

  guardarVariablesEnMemoria(variables: VariablesAgrupadas[]) {
    const procesadas = this.procesarVariables(variables);
    this.localStorageService.set('variablesSistema', procesadas);
    this.variablesSubject.next(procesadas);
  }

  guardarVariablesDefectoEnMemoria(variables: VariablesAgrupadas[]) {
    let grupos = variables.filter(item => item.subgrupos);
    let subgrupos = [];
    for (let grupo of grupos) {
      for (let subgrupo of grupo.subgrupos) {
        subgrupos.push(subgrupo);
      }
    }
    this.localStorageService.set('valoresPorDefecto', subgrupos);
  }

  obtenerValoresPorDefectoEnMemoria(): VariableSubgrupos[] {
    if (typeof this.localStorageService.get('valoresPorDefecto') === 'undefined'
      || this.localStorageService.get('valoresPorDefecto') === null
    ) {
      return [];
    } else {
      return this.localStorageService.get('valoresPorDefecto');
    }
  }


  obtenerVariablesEnMemoria(): ConfiguracionesModel {
    if (
      typeof this.localStorageService.get('variablesSistema') === 'undefined'
      || this.localStorageService.get('variablesSistema') === null
      || Object.keys(this.localStorageService.get('variablesSistema')).length === 0
    ) {
      const configuraciones: ConfiguracionesModel = new ConfiguracionesModel();
      return configuraciones;
    }

    const configuracionesModel: ConfiguracionesModel = new ConfiguracionesModel();
    return { ...configuracionesModel, ...this.localStorageService.get('variablesSistema') };
  }

  private procesarVariables(variables: VariablesAgrupadas[]) {
    let obj = {};
    for (const item of variables) {
      for (const variable of item.configuracionVariable) {
        obj[variable.slug] = variable.valor;
      }
    }
    return obj;
  }

  obtenerVariables(params) {
    return this.http.get(`${environment.urlApiNew}configuraciones`, { params });
  }

  obtenerDatosPorDefecto(slugSubgrupo: string) {
    return this.http.get<RespuestaApi<DatosPorDefecto>>(
      `${environment.urlApiNew}configuraciones/datos-defecto`,
      { params: { slug: slugSubgrupo } }
    );
  }


  obtenerVariablesPorModulo(moduloRuta: Array<any>) {
    const params = { grupos: moduloRuta };
    return this.http.get(`${environment.urlApiNew}configuraciones`, { params });
  }

  verificarExisteMenu(moduloRuta) {
    const params = { grupos: moduloRuta, formato: 'verficacion' };
    return this.http.get(`${environment.urlApiNew}configuraciones`, { params })
  }

  guardarVariables(data) {
    return this.http.post(`${environment.urlApiNew}configuraciones`, data);
  }

  actualizaMenuOperacionesHeader(estado) {
    this.menuOperacionHeaderSub.next(estado);
  }

  /**
   * Carga las variables y las graba en memoria cuando se actualiza la pagina
   */
  actualizarVariablesMemoria() {
    const parametros = { formato: 'agrupado' };
    let variablesAgrupadas;
    this.obtenerVariables(parametros).subscribe((r: RespuestaApi) => {
      variablesAgrupadas = r.data;
      this.guardarVariablesEnMemoria(variablesAgrupadas);
    }, e => {
      this.root.validaError(e);
    });

  }

  // obtenerDatosPorDefecto(subgrupo_slug: string) {
  //   const subgrupos = this.obtenerValoresPorDefectoEnMemoria();
  //   let resultado = subgrupos.find(item => item.subgrupo.slug == subgrupo_slug) || {}
  //   return resultado;
  // }


  deshabilitarCampos(form: FormGroup, datos, accion = 'crear') {

    let deshabilitarAccion = 'deshabilitado_crear';
    if (accion == 'editar') {
      deshabilitarAccion = 'deshabilitado_editar';
    }
    for (let item of Object.keys(datos)) {
      if (datos[item][deshabilitarAccion]) {
        form.controls[item].disable();
      }
    }
  }


}
