import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import * as Ws from '@adonisjs/websocket-client';

import { environment } from 'environments/environment';
import { Notificaciones } from 'app/shared/interfaces/notificaciones';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesWsService {

  private wsUrl = environment.urlApiNewWs;
  socket;
  canalNotificaciones;

  notificacionesSubjec = new BehaviorSubject<Notificaciones>(null);
  $notificaciones = this.notificacionesSubjec.asObservable();


  constructor() { }

  public conexionWs() {
    // this.socket = Ws(this.wsUrl, {});
    // this.socket.connect();

    // this.canalesConectados();

  }

  private canalesConectados() {
    this.conexionCanalNotificaciones();
  }

  private conexionCanalNotificaciones() {
    this.canalNotificaciones = this.socket.subscribe('notificaciones');

    this.canalNotificaciones.on('actualizacion', (data: Notificaciones) => {
      console.log('actualizacion recibido', data);
      const newData = { ...data, tipo: 'actualizacion' };
      this.notificacionesSubjec.next(newData);
    });



  }



}
