import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Layouts
import { FullLayoutComponent } from './layouts/full-layout.component';
import { SimpleLayoutComponent } from './layouts/simple-layout.component';
import { AuthGuard } from './shared/security/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { TestImpresionZebraComponent } from './pages/test-impresion-zebra/test-impresion-zebra.component';
import { LoginRecuperacionComponent } from './pages/login-recuperacion/login-recuperacion.component';

export const routes: Routes = [
    {
        path: '',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './pages/pages.module#PagesModule',
                canActivate: [AuthGuard],


            },
        ],
    },
    {
        path: 'login',
        component: SimpleLayoutComponent,
        children: [
            {
                path: '',
                component: LoginComponent

            },
        ],
    },
    {
        path: 'login-recuperacion',
        component: SimpleLayoutComponent,
        children: [
            {
                path: '',
                component: LoginRecuperacionComponent

            },
        ],
    },
    {
        path: 'admin',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './modules/configuraciones/configuraciones.module#ConfiguracionesModule',
                canActivate: [AuthGuard],
            },
        ],
    },
    {
        path: 'ofertas',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './modules/ofertas/ofertas.module#OfertasModule',
                canActivate: [AuthGuard],
            },
        ],
    },
    // {
    //     path: 'ofertas',
    //     component: FullLayoutComponent,
    //     children: [
    //         {
    //             path: '',
    //             component: EnContruccionComponent,
    //         },
    //     ]
    // },

    // {
    //     path: 'inventario',
    //     component: FullLayoutComponent,
    //     children: [
    //         {
    //             path: '',
    //             component: EnContruccionComponent,
    //         },
    //     ]
    // },
    {
        path: 'inventario',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './modules/inventario/inventario.module#InventarioModule',
                canActivate: [AuthGuard],
            },
        ],
    },
    {
        path: 'reportes',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './modules/reportes/reportes.module#ReportesModule',
                canActivate: [AuthGuard],
            },
        ],
    },
    {
        path: 'pos',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './modules/pos/pos.module#PosModule',
                canActivate: [AuthGuard],
            },
        ],
    },

    {
        path: 'cobranzas',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './modules/cobranzas/cobranzas.module#CobranzasModule',
                canActivate: [AuthGuard]

            },
        ]
    },
    {
        path: 'cargo',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './modules/cargo/cargo.module#CargoModule',
                canActivate: [AuthGuard]

            },
        ]
    },
    {
        path: 'tienda',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './modules/tienda/tienda.module#TiendaModule',
                canActivate: [AuthGuard]
            },
        ]
    },
    {
        path: 'sincronizador',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './modules/sincronizador/sincronizador.module#SincronizadorModule',
                canActivate: [AuthGuard]

            },
        ]
    },
    {
        path: 'test-impresion-zebra',
        component: SimpleLayoutComponent,
        children: [
            {
                path: '',
                component: TestImpresionZebraComponent

            },
        ],
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }