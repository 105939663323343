/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../shared/components/sidebar/sidebar.component.ngfactory";
import * as i2 from "../shared/components/sidebar/sidebar.component";
import * as i3 from "../services/root.service";
import * as i4 from "angular-2-local-storage";
import * as i5 from "../services/configuraciones.service";
import * as i6 from "../shared/components/header/header.component.ngfactory";
import * as i7 from "../shared/components/header/header.component";
import * as i8 from "../services/cajas-usuario.service";
import * as i9 from "@angular/router";
import * as i10 from "ngx-bootstrap/modal";
import * as i11 from "./full-layout.component";
var styles_FullLayoutComponent = [];
var RenderType_FullLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FullLayoutComponent, data: {} });
export { RenderType_FullLayoutComponent as RenderType_FullLayoutComponent };
export function View_FullLayoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["appMobileSidebarClose", ""], ["class", "app-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-sidebar", [], null, null, null, i1.View_SidebarComponent_0, i1.RenderType_SidebarComponent)), i0.ɵdid(2, 114688, null, 0, i2.SidebarComponent, [i3.RootService, i4.LocalStorageService, i5.ConfiguracionesService], { menu: [0, "menu"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 6, "main", [["class", "main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [["class", "container-fluid d-flex contenido-main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "app-header", [], null, null, null, i6.View_HeaderComponent_0, i6.RenderType_HeaderComponent)), i0.ɵdid(6, 245760, null, 0, i7.HeaderComponent, [i3.RootService, i8.CajasUsuarioService, i9.Router, i5.ConfiguracionesService, i9.ActivatedRoute, i10.BsModalService], { usuario: [0, "usuario"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "content-body w-100"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(9, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menu; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.usuario; _ck(_v, 6, 0, currVal_1); _ck(_v, 9, 0); }, null); }
export function View_FullLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_FullLayoutComponent_0, RenderType_FullLayoutComponent)), i0.ɵdid(1, 114688, null, 0, i11.FullLayoutComponent, [i4.LocalStorageService, i9.Router, i3.RootService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FullLayoutComponentNgFactory = i0.ɵccf("app-dashboard", i11.FullLayoutComponent, View_FullLayoutComponent_Host_0, {}, {}, []);
export { FullLayoutComponentNgFactory as FullLayoutComponentNgFactory };
