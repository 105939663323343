import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { VariablesAgrupadas, VariableSubgrupos } from '../shared/interfaces/variables';
import { ConfiguracionesModel } from '../models/configuraciones.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "angular-2-local-storage";
import * as i3 from "./root.service";
export class ConfiguracionesService {
    constructor(http, localStorageService, root) {
        this.http = http;
        this.localStorageService = localStorageService;
        this.root = root;
        this.variablesSubject = new BehaviorSubject(null);
        this.$variables = this.variablesSubject.asObservable();
        this.menuOperacionHeaderSub = new BehaviorSubject(false);
        this.$menuOperacionHeader = this.menuOperacionHeaderSub.asObservable();
    }
    nombres() {
        return {
            categoria_uno: 'Categoria uno',
            categoria_dos: 'Categoria dos',
            categoria_tres: 'Categoria tres',
            precio_minimo: 10,
            marca: 'Laboratorio',
        };
    }
    guardarVariablesEnMemoria(variables) {
        const procesadas = this.procesarVariables(variables);
        this.localStorageService.set('variablesSistema', procesadas);
        this.variablesSubject.next(procesadas);
    }
    guardarVariablesDefectoEnMemoria(variables) {
        let grupos = variables.filter(item => item.subgrupos);
        let subgrupos = [];
        for (let grupo of grupos) {
            for (let subgrupo of grupo.subgrupos) {
                subgrupos.push(subgrupo);
            }
        }
        this.localStorageService.set('valoresPorDefecto', subgrupos);
    }
    obtenerValoresPorDefectoEnMemoria() {
        if (typeof this.localStorageService.get('valoresPorDefecto') === 'undefined'
            || this.localStorageService.get('valoresPorDefecto') === null) {
            return [];
        }
        else {
            return this.localStorageService.get('valoresPorDefecto');
        }
    }
    obtenerVariablesEnMemoria() {
        if (typeof this.localStorageService.get('variablesSistema') === 'undefined'
            || this.localStorageService.get('variablesSistema') === null
            || Object.keys(this.localStorageService.get('variablesSistema')).length === 0) {
            const configuraciones = new ConfiguracionesModel();
            return configuraciones;
        }
        const configuracionesModel = new ConfiguracionesModel();
        return Object.assign({}, configuracionesModel, this.localStorageService.get('variablesSistema'));
    }
    procesarVariables(variables) {
        let obj = {};
        for (const item of variables) {
            for (const variable of item.configuracionVariable) {
                obj[variable.slug] = variable.valor;
            }
        }
        return obj;
    }
    obtenerVariables(params) {
        return this.http.get(`${environment.urlApiNew}configuraciones`, { params });
    }
    obtenerDatosPorDefecto(slugSubgrupo) {
        return this.http.get(`${environment.urlApiNew}configuraciones/datos-defecto`, { params: { slug: slugSubgrupo } });
    }
    obtenerVariablesPorModulo(moduloRuta) {
        const params = { grupos: moduloRuta };
        return this.http.get(`${environment.urlApiNew}configuraciones`, { params });
    }
    verificarExisteMenu(moduloRuta) {
        const params = { grupos: moduloRuta, formato: 'verficacion' };
        return this.http.get(`${environment.urlApiNew}configuraciones`, { params });
    }
    guardarVariables(data) {
        return this.http.post(`${environment.urlApiNew}configuraciones`, data);
    }
    actualizaMenuOperacionesHeader(estado) {
        this.menuOperacionHeaderSub.next(estado);
    }
    /**
     * Carga las variables y las graba en memoria cuando se actualiza la pagina
     */
    actualizarVariablesMemoria() {
        const parametros = { formato: 'agrupado' };
        let variablesAgrupadas;
        this.obtenerVariables(parametros).subscribe((r) => {
            variablesAgrupadas = r.data;
            this.guardarVariablesEnMemoria(variablesAgrupadas);
        }, e => {
            this.root.validaError(e);
        });
    }
    // obtenerDatosPorDefecto(subgrupo_slug: string) {
    //   const subgrupos = this.obtenerValoresPorDefectoEnMemoria();
    //   let resultado = subgrupos.find(item => item.subgrupo.slug == subgrupo_slug) || {}
    //   return resultado;
    // }
    deshabilitarCampos(form, datos, accion = 'crear') {
        let deshabilitarAccion = 'deshabilitado_crear';
        if (accion == 'editar') {
            deshabilitarAccion = 'deshabilitado_editar';
        }
        for (let item of Object.keys(datos)) {
            if (datos[item][deshabilitarAccion]) {
                form.controls[item].disable();
            }
        }
    }
}
ConfiguracionesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfiguracionesService_Factory() { return new ConfiguracionesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.RootService)); }, token: ConfiguracionesService, providedIn: "root" });
