/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-eliminar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-bootstrap/modal";
import * as i3 from "ngx-bootstrap/component-loader";
import * as i4 from "./modal-eliminar.component";
var styles_ModalEliminarComponent = [i0.styles];
var RenderType_ModalEliminarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalEliminarComponent, data: {} });
export { RenderType_ModalEliminarComponent as RenderType_ModalEliminarComponent };
export function View_ModalEliminarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { mdlEliminar: 0 }), i1.ɵqud(671088640, 2, { modalConfirmar: 0 }), (_l()(), i1.ɵeld(2, 16777216, null, null, 19, "div", [["aria-hidden", "true"], ["aria-labelledby", "myModalLabel"], ["bsModal", ""], ["class", "modal fade modal-danger"], ["id", "modal-eliminar"], ["role", "dialog"], ["tabindex", "1"]], null, [[null, "keyup.enter"], [null, "mousedown"], [null, "mouseup"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClickStarted($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseup" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onClickStop($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.esc" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).onEsc($event) !== false);
        ad = (pd_2 && ad);
    } if (("keyup.enter" === en)) {
        var pd_3 = (_co.ejecutarFuncion() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 212992, [[1, 4], ["ModalEliminar", 4]], 0, i2.ModalDirective, [i1.ElementRef, i1.ViewContainerRef, i1.Renderer2, i3.ComponentLoaderFactory], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 17, "div", [["class", "modal-dialog "], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 16, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(12, 0, null, null, 9, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "btn btn-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["class", "fa fa-ban"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancelar"])), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["class", "btn btn-success"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ejecutarFuncion() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "i", [["class", "fa fa-check"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Aceptar"])), (_l()(), i1.ɵeld(22, 16777216, null, null, 17, "div", [["aria-hidden", "true"], ["aria-labelledby", "myModalLabel"], ["bsModal", ""], ["id", "modal-normal"], ["role", "dialog"], ["tabindex", "1"]], [[8, "className", 0]], [[null, "keyup.enter"], [null, "mousedown"], [null, "mouseup"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).onClickStarted($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseup" === en)) {
        var pd_1 = (i1.ɵnov(_v, 23).onClickStop($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.esc" === en)) {
        var pd_2 = (i1.ɵnov(_v, 23).onEsc($event) !== false);
        ad = (pd_2 && ad);
    } if (("keyup.enter" === en)) {
        var pd_3 = (_co.ejecutarFuncion() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 212992, [[2, 4], ["ModalConfirmar", 4]], 0, i2.ModalDirective, [i1.ElementRef, i1.ViewContainerRef, i1.Renderer2, i3.ComponentLoaderFactory], null, null), (_l()(), i1.ɵeld(24, 0, null, null, 15, "div", [["class", "modal-dialog "], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 14, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(28, null, ["", ""])), (_l()(), i1.ɵeld(29, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(32, 0, null, null, 0, "div", [["class", "modal-body"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 2, "button", [["class", "btn btn-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 0, "i", [["class", "fa fa-ban"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancelar"])), (_l()(), i1.ɵeld(37, 0, null, null, 2, "button", [["class", "btn btn-success"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ejecutarFuncion() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(38, 0, null, null, 0, "i", [["class", "fa fa-check"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Aceptar "]))], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 23, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options.modalTitle; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.options.modalMsg; _ck(_v, 13, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "modal fade ", _co.options.modalClass, ""); _ck(_v, 22, 0, currVal_2); var currVal_3 = _co.options.modalTitle; _ck(_v, 28, 0, currVal_3); var currVal_4 = _co.options.modalMsg; _ck(_v, 32, 0, currVal_4); }); }
export function View_ModalEliminarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-eliminar", [], null, null, null, View_ModalEliminarComponent_0, RenderType_ModalEliminarComponent)), i1.ɵdid(1, 114688, null, 0, i4.ModalEliminarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalEliminarComponentNgFactory = i1.ɵccf("app-modal-eliminar", i4.ModalEliminarComponent, View_ModalEliminarComponent_Host_0, { parametros: "parametros" }, {}, []);
export { ModalEliminarComponentNgFactory as ModalEliminarComponentNgFactory };
