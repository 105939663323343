import * as tslib_1 from "tslib";
import { TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import * as queryString from 'query-string';
import { mensajes } from 'app/data/mensajes';
export class RootService {
    constructor(localStorage, router, route, toastr, scrollToService, location) {
        this.localStorage = localStorage;
        this.router = router;
        this.route = route;
        this.toastr = toastr;
        this.scrollToService = scrollToService;
        this.location = location;
        this.title = new BehaviorSubject('Titulo página');
        this.currentTitle = this.title.asObservable();
        this.usuario = new BehaviorSubject('');
        this.listamenu = new BehaviorSubject('');
        // barra de menu
        this.menuAbierto = new BehaviorSubject('');
        this.$menuAbierto = this.menuAbierto.asObservable();
        // barra de configuracion
        this.barraConfiguraciones = new BehaviorSubject(false);
        this.$barraConfiguraciones = this.barraConfiguraciones.asObservable();
    }
    establecerModalTemplate(template) {
        this.templateModal = template;
    }
    setTitle(title) {
        this.title.next(title);
    }
    // Permite cerrar la sesion
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const locales = this.obtenerConfiguracionesLocales();
            yield this.localStorage.clearAll();
            this.guardarConfiguraciones(locales);
            this.actualizarUsuario(null);
            this.router.navigate(['login']);
        });
    }
    actualizarUsuario(usuario) {
        this.usuario.next(usuario);
    }
    getIVA() {
        return 19;
    }
    getRutaInicio() {
        this.usuarioTemp = this.localStorage.get('usuario');
        return this.usuarioTemp.modulos[0].ruta;
    }
    obtenerMenuRuta(ruta) {
        const usuario = this.localStorage.get('usuario');
        const modulos = usuario.modulos;
        const submodulos = modulos.find(item => (item.ruta.join('/') === ruta));
        return submodulos;
    }
    reducirBarraLateralMenu() {
    }
    menuAbiertoEstado(estado) {
        console.log('menuAbiertoEstado:', estado);
        this.localStorage.set('barraLateralAbierta', estado);
        this.menuAbierto.next(estado);
    }
    validaError(e, tituloError = mensajes.tituloError) {
        console.error('valida error', e);
        if (e.hasOwnProperty('errorResponse') && e.errorResponse.mensaje) {
            this.toastr.error(e.errorResponse.mensaje, tituloError);
        }
        else if (e.error && e.error.hasOwnProperty('mensaje')) {
            this.toastr.error(this.limpiarMensajeError(e.error.mensaje), tituloError);
        }
        else if (e.error && e.hasOwnProperty('mensaje') && e.mensaje) {
            this.toastr.error(this.limpiarMensajeError(e.mensaje), tituloError);
        }
        else if (e.error && e.status === 0) {
            this.toastr.error(mensajes.servidorSinComunicacion, tituloError);
        }
        else {
            this.toastr.error(mensajes.comunicacionServidor, tituloError);
        }
    }
    validaErrorImpresora(e, tituloError = mensajes.tituloError) {
        console.error('valida error', e);
        if (e.hasOwnProperty('errorResponse') && e.errorResponse.mensaje) {
            this.toastr.error(e.errorResponse.mensaje, tituloError);
        }
        else if (e.error && e.error.hasOwnProperty('mensaje')) {
            this.toastr.error(this.limpiarMensajeError(e.error.mensaje), tituloError);
        }
        else if (e.error && e.hasOwnProperty('mensaje') && e.mensaje) {
            this.toastr.error(this.limpiarMensajeError(e.mensaje), tituloError);
        }
        else if (e.error && e.status === 0) {
            this.toastr.error(mensajes.impresoraSinComunicacion, tituloError);
        }
        else {
            this.toastr.error(mensajes.comunicacionServidor, tituloError);
        }
    }
    restarFechas(a, b) {
        const horasConDecimal = Math.abs(a.getTime() - b.getTime()) / (1000 * 60 * 60);
        const horas = Math.floor(horasConDecimal);
        const minutos = Math.floor(Math.abs(horasConDecimal - horas) * 60);
        return { minutos, horas };
    }
    errorLoadImage($event) {
        $event.target.src = 'assets/img/general/no_producto.png';
    }
    /**
     * opciones para las tablas con serverside datatables
     */
    obtenerOpcionesDt() {
        const opciones = {
            pagingType: 'full_numbers',
            pageLength: 25,
            retrieve: true,
            serverSide: true,
            processing: true,
            language: {
                url: 'assets/js/datatable/Spanish.json',
            },
            columnDefs: [
                {
                    targets: 'sort-false',
                    orderable: false,
                },
            ],
        };
        return opciones;
    }
    sumarDias(fecha, plazo) {
        return moment(fecha, 'YYYY-MM-DD').add(plazo, 'days');
    }
    delay(ms) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => setTimeout(resolve, ms));
        });
    }
    /**
     * obtiene la url del parametros  y la decodifica
     */
    obtenerUrlVolver(urlVolver) {
        const urlDecode = decodeURIComponent(urlVolver);
        const urlSplit = urlDecode.split('?');
        const url = urlSplit[0];
        const urlParams = urlSplit[1];
        const parametros = queryString.parse(urlParams);
        return {
            url: url.split('/'),
            parametros
        };
    }
    scrollTo(target, offset = 20, duration = 650) {
        const config = {
            // container: 'custom-container',
            target,
            duration,
            easing: 'easeOutElastic',
            offset,
        };
        this.scrollToService.scrollTo(config);
    }
    limpiarRut(texto) {
        const searchRegExp = /\./g;
        const replaceWith = '';
        let r1 = texto.replace(searchRegExp, replaceWith);
        // let a = r1.split('-');
        // return a[0].trim();
        return r1;
    }
    limpiarMensajeError(mensaje) {
        const obj = {
            'ErrorHttpException: ': '',
            'Error: HttpException: ': '',
            'Error: ': ''
        };
        return this.replaceAll(mensaje, obj);
    }
    replaceAll(str, mapObj) {
        const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');
        // console.log(re);
        return str.replace(re, (matched) => {
            return mapObj[matched];
        });
    }
    procesarRut(rutCompleto) {
        let rutLimpio = this.limpiarRut(rutCompleto);
        let rut = null, dv = null;
        // verificamos si tiene digito verificador
        let rutSplit = rutLimpio.split('-');
        if (rutSplit.length > 1) {
            rut = rutSplit[0];
            dv = rutSplit[1];
        }
        else {
            rut = rutLimpio.substring(0, rutLimpio.length - 1);
            dv = rutLimpio.substring(rutLimpio.length - 1, rutLimpio.length);
        }
        return { rut, dv };
    }
    delayClearTime(callback, ms) {
        let timer = 0;
        return function () {
            var context = this, args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                callback.apply(context, args);
            }, ms || 0);
        };
    }
    calcularPorcentaje(parte, total) {
        const p = ((Number(parte) * 100) / Number(total)).toFixed(1);
        return Number(p);
    }
    actualizarUrl(params, removerValoresNulos = true) {
        let parametros = this.route.snapshot.queryParams;
        if (removerValoresNulos) {
            Object.keys(parametros).map(k => {
                if (parametros[k] === null) {
                    delete parametros[k];
                }
            });
        }
        parametros = Object.assign({}, parametros, params);
        const url = this.router.createUrlTree([], { relativeTo: this.route, queryParams: parametros }).toString();
        this.location.go(url);
    }
    barraConfiguracion(abrir = false) {
        this.barraConfiguraciones.next(abrir);
    }
    guardarConfiguraciones(datos) {
        let locales = this.localStorage.get('configLocales');
        locales = Object.assign({}, locales, datos);
        this.localStorage.set('configLocales', locales);
    }
    obtenerConfiguracionesLocales() {
        let locales = this.localStorage.get('configLocales');
        if (locales == null) {
            locales = {};
        }
        return locales;
    }
}
