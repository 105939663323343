/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./animacion-cargando.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./animacion-cargando.component";
import * as i3 from "../../../services/configuraciones.service";
var styles_AnimacionCargandoComponent = [i0.styles];
var RenderType_AnimacionCargandoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AnimacionCargandoComponent, data: {} });
export { RenderType_AnimacionCargandoComponent as RenderType_AnimacionCargandoComponent };
export function View_AnimacionCargandoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "app-loading"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "loading-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "loading-ico"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cargando..."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.cargando; _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.configuraciones == null) ? null : _co.configuraciones.logo_loading); _ck(_v, 2, 0, currVal_1); }); }
export function View_AnimacionCargandoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-animacion-cargando", [], null, null, null, View_AnimacionCargandoComponent_0, RenderType_AnimacionCargandoComponent)), i1.ɵdid(1, 114688, null, 0, i2.AnimacionCargandoComponent, [i3.ConfiguracionesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AnimacionCargandoComponentNgFactory = i1.ɵccf("app-animacion-cargando", i2.AnimacionCargandoComponent, View_AnimacionCargandoComponent_Host_0, { cargando: "cargando" }, {}, []);
export { AnimacionCargandoComponentNgFactory as AnimacionCargandoComponentNgFactory };
