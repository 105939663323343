import { Component, OnInit, Input } from '@angular/core';

import { LocalStorageService } from 'angular-2-local-storage';

import { RootService } from '../../../services/root.service';
import { Modulo } from '../../interfaces/modulo';
import { ConfiguracionesService } from '../../../services/configuraciones.service';
import { ConfiguracionesModel } from '../../../models/configuraciones.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input() menu: Modulo[] = [];
  configuraciones: ConfiguracionesModel;

  constructor(
    public root: RootService,
    public localStorage: LocalStorageService,
    private configuracionesService: ConfiguracionesService
  ) { }

  ngOnInit() {

    if (this.localStorage.get('barraLateralAbierta') != null) {
      this.cambiaEstadoMenu(this.localStorage.get('barraLateralAbierta'));
    }

    this.root.$menuAbierto.subscribe(r => {
      this.cambiaEstadoMenu(r);
    });


    this.configuraciones = this.configuracionesService.obtenerVariablesEnMemoria();
    this.configuracionesService.$variables.subscribe(variables => {
      if (variables != null) {
        this.configuraciones = variables;
      }
    });

  }

  cambiaEstadoMenu(estado) {

    // console.log(estado, document.body.classList.contains('sidebar-minimized') )
    if (estado === false) {
      document.body.classList.add('sidebar-minimized');
    } else {
      document.body.classList.remove('sidebar-minimized');

    }
  }

}
