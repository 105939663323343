import * as Html2Pdf from 'html2pdf.js';


export class GenerarPDF {

    /**
     * Redodea los numeros decimales a enteros
     */
    convertirHtmlPdf(elemtoHTML, configuraciones?: any ) {
        return Html2Pdf().from(elemtoHTML).set(configuraciones).save();
    }
}