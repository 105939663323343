export class Perfil {
    public id: number;
    public nombre: string;
    public permisos: any;

    constructor() {
        this.id = 0;
        this.nombre = '';
        this.permisos = '';
    }
}
