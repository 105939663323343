<app-animacion-cargando [cargando]="cargando"></app-animacion-cargando>
<div class="app flex-row align-items-center container-login">
    <div class="container">
        <!-- Notificaciones -->
        <app-msgbox> </app-msgbox>

        <div class="row justify-content-center">
            <div class="col-lg-8 col-12">
                <div class="card-group mb-0">
                    <div class="card p-4 card-shadow">
                        <div class="card-block">

                            <div class="flex d-none d-sm-block">
                                <div class="login-text-div">
                                    <h2>MOUNTAIN POS</h2>
                                    <p class="text-muted">
                                        Ingrese los datos de su cuenta
                                    </p>
                                </div>

                                <div class="login-img-div">
                                    <img src="assets/img/logoIngydev2.png" />
                                </div>
                            </div>

                            <div class="row d-none d-block d-sm-none text-center">

                                <div class="col-8 offset-2 mb-2">
                                    <img class="img-fluid" src="assets/img/logoIngydev2.png" />
                                </div>

                                <div class="login-text-div">
                                    <h2>MOUNTAIN POS</h2>
                                    <p class="text-muted">
                                        Ingrese los datos de su cuenta
                                    </p>
                                </div>


                            </div>
                            <form [formGroup]="formRecuperar" *ngIf="recuperandoClave">

                                <div class="input-group mb-4">
                                    <span class="input-group-addon">
                                        <i class="icon-lock"></i> <span>Contraseña</span> </span>
                                    <input type="password" class="form-control" placeholder="´Contraseña"
                                        formControlName="clave_nueva" />
                                </div>

                                <div class="input-group mb-4">
                                    <span class="input-group-addon">
                                        <i class="icon-lock"></i> <span>Repetir contraseña</span> </span>
                                    <input type="password" class="form-control" placeholder="Repetir contraseña"
                                        formControlName="clave_nueva_repetir" />
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <button [disabled]="formRecuperar.invalid"
                                            class="btn btn-primary btn-ladda float-right" data-style="expand-left"
                                            (click)="cambiarClave()">
                                            <i class="fa fa-sign-in" aria-hidden="true"></i>
                                            Cambiar contraseña
                                        </button>
                                    </div>
                                </div>

                            </form>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>