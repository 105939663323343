import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';

import { MyglobalService } from '../../myglobal.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { Router } from '@angular/router';


@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private gbl: MyglobalService,
        private localStorage: LocalStorageService,
        private router: Router
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,

    ): boolean {
        if (this.localStorage.get('usuario') != null) {
            return true;
        } else {
            this.router.navigate(['/', 'login']);
            return false;
        }

    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {

        if (!this.canActivate(next, state)) {
            return false;
        }

        console.log('entra child');
        // return autorizado;
        // return true;
    }



    private getMenu() {
        // return (this.localg.get('menu') || {}) as any;
    }

    private deconstruirRuta(ruta: string) {
        return ruta
            .split('/')
            .map(s => s.trim())
            .filter(s => s.length > 0);
    }

    private normalizarRuta(ruta: string) {
        return this.deconstruirRuta(ruta).join('/');
    }


    // canActivateChild(
    //     next: ActivatedRouteSnapshot,
    //     state: RouterStateSnapshot
    // ): boolean {
    //     if (!this.canActivate(next, state)) {
    //         return false;
    //     }

    //     const [stateUrlPadre, ...stateUrlResto] = this.deconstruirRuta(
    //         state.url
    //     );

    //     const permisosModulo = this.getMenu().modulos.find(
    //         x => this.deconstruirRuta(x.ruta)[0] === stateUrlPadre
    //     );

    //     if (!permisosModulo) {
    //         return false;
    //     }

    //     if (stateUrlResto.length === 0) {
    //         return true;
    //     }

    //     const [stateUrlHijos, queryString] = stateUrlResto.join('').split('?');

    //     const stateUrlNormalizada = this.normalizarRuta(
    //         `${stateUrlPadre}/${stateUrlHijos}`
    //     );

    //     const permisosComponente = permisosModulo.menu.find(
    //         x => this.normalizarRuta(x.ruta).match(stateUrlNormalizada) != null
    //     );

    //     if (!permisosComponente) {
    //         return false;
    //     }

    //     const autorizado = permisosComponente.permitir;

    //     return autorizado;
    // }
}
