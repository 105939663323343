import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { Router } from '@angular/router';

import { Usuario } from '../../shared/interfaces/usuario';
import { RootService } from '../../services/root.service';

import { ImpresoraZebraService } from '../../services/impresora-zebra.service';

@Component({
    templateUrl: 'test-impresion-zebra.component.html',
    styleUrls: ['./test-impresion-zebra.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TestImpresionZebraComponent implements OnInit {

    public usuario: Usuario;
    public usx: any;
    public cargando = false;

    dataEtiqueta = '^XA^FO200,200^A0N36,36^FDTest Label^FS^XZ';


    constructor(
        public localStore: LocalStorageService,
        public router: Router,
        private impresoraZebraService: ImpresoraZebraService
    ) {
    }

    ngOnInit() {

        this.impresoraZebraService.$impresoraZebra.subscribe(r => {
            console.log(r);
            if (r.accion == 'conexion' && r.conectada == true) {
                this.impresoraZebraService.imprimir(this.dataEtiqueta);
            }
        })

    }

    verificarConexion() {

        this.impresoraZebraService.conexion();
    }

    imprimirCodigo(){

        
    }


}
