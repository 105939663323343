import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';

import * as moment from 'moment';
import * as u from 'underscore.string';
import { LocalStorageService } from 'angular-2-local-storage';

import { environment } from '../../environments/environment';
import { DocumentoVista, DocumentoReferencia } from '../shared/interfaces/documentos';
import { RespuestaApi } from 'app/shared/interfaces/respuesta-api';
import { ConfiguracionesService } from './configuraciones.service';
import { ConfiguracionesModel } from 'app/models/configuraciones.model';
import { ComprobanteImpresion, ImpresoraComprobanteDocumento, ImpresoraConfiguracion } from 'app/shared/interfaces/impresora';
import { ComprobanteDatosFactura } from '../shared/interfaces/impresora';
import { Contacto } from 'app/shared/interfaces/contacto';
import { ContactosService } from 'app/services/contactos.service';
import { ImpresoraService } from './impresora.service';
import { TitleCasePipe } from '@angular/common';
import { RootService } from './root.service';
import { ImpresionService } from './impresion.service';



@Injectable()
export class DocumentosService {

    private documento = new Subject<DocumentoVista>();
    public $documento = this.documento.asObservable();

    private documentoReferencia = new Subject<DocumentoReferencia[]>();
    public $documentoReferencia = this.documentoReferencia.asObservable();
    configuraciones: ConfiguracionesModel;
    impresoraConfiguracion: ImpresoraConfiguracion;

    constructor(
        private http: HttpClient,
        private configuracionesService: ConfiguracionesService,
        private impresoraService: ImpresoraService,
        private titleCase: TitleCasePipe,
        private root: RootService,
        private localStorage: LocalStorageService,
        private impresionService: ImpresionService,
        private contactosService: ContactosService

    ) {


    }

    obtieneDetalleDocumento(id: string, params = {}) {
        return this.http.get(environment.urlApiNew + 'Documentos/' + id, { params });
    }

    ver(id: string) {
        return this.http.get(environment.urlApiNew + 'Documentos/' + id);
    }

    emitirDocumento(documento: DocumentoVista) {
        console.log('Emite documento');
        this.documento.next(documento);
    }

    emitirDocumentosReferencia(documentoRef: DocumentoReferencia[]) {
        this.documentoReferencia.next(documentoRef);
    }

    facturarDocumento(params) {
        return this.http.get(environment.urlApiNew + `documentos/facturar`, { params });
    }

    urlPdf(documentoId) {
        return `${environment.urlApiNew}documentos/pdf/${documentoId}`;
    }



    async imprimirDTE(documentoId: string) {
        const comprobante = await this.obtenerDatosDocumento(documentoId);
        await this.impresionService.imprimirData(comprobante);
    }


    async obtenerDatosDocumento(documentoId, totalCaracteres = null, dataComprobante = null) {


        totalCaracteres = (totalCaracteres) ? totalCaracteres : 46;
        this.configuraciones = this.configuracionesService.obtenerVariablesEnMemoria();

        const comprobante: ComprobanteImpresion = {
            configuracion: [
                {
                    nombre: 'lineas',
                    valor: totalCaracteres
                },
                // {
                //     nombre: 'impresora',
                //     valor: 'Microsoft Print to PDF'
                // }
            ],
            dataTexto: []
        };
        const documentoRespuesta: RespuestaApi = await this.ver(documentoId).toPromise();
        const documento: DocumentoVista = documentoRespuesta.data;

        // CABECERA DOCUMENTO
        comprobante.dataTexto.push({
            texto: documento.tipoDocumento.descripcion + ' N° ' + documento.numero_documento,
            alineacion: 'center'
        });
        comprobante.dataTexto.push({
            texto: this.configuraciones.facturacion_emisor_razon_social,
            alineacion: 'center',
            negrita: true
        });
        const rutCompleto = this.root.procesarRut(this.configuraciones.facturacion_emisor_rut);
        const rut = u.numberFormat(Number(rutCompleto.rut), 0, ',', '.');
        comprobante.dataTexto.push({
            texto: rut + '-' + rutCompleto.dv,
            alineacion: 'center'
        });
        comprobante.dataTexto.push({ texto: null, alineacion: 'center' });

        comprobante.dataTexto.push({
            texto: 'Telefono: ' + this.configuraciones.facturacion_emisor_telefono,
            alineacion: 'left' 
        });
        /*
        comprobante.dataTexto.push({
            texto: 'Dirección: ' + this.configuraciones.facturacion_emisor_direccion,
            alineacion: 'left' 
        });*/
        if (this.configuraciones.facturacion_emisor_direccion) {
            let texto = 'Dirección: '+this.configuraciones.facturacion_emisor_direccion;
            const itemDireccion = this.impresoraService.textoLargo(texto, totalCaracteres, 'left');
            if (texto.length >= totalCaracteres) {
                itemDireccion.forEach((lineaTexto, index) => {
                    comprobante.dataTexto.push({
                        texto: lineaTexto,
                        alineacion: 'left'
                    });
                    
                });
            } else {
                comprobante.dataTexto.push({
                    texto: texto,
                    alineacion: 'left' 
                });
            }
        }
        comprobante.dataTexto.push({
            texto:  this.configuraciones.facturacion_emisor_ciudad,
            alineacion: 'left' 
        });



        comprobante.dataTexto.push({
            texto: 'Bodega: ' + documento.ordenTransporte.bodega.nombre,
            alineacion: 'left' 
        });
        comprobante.dataTexto.push({
            texto: 'Dirección: ' + documento.ordenTransporte.bodega.direccion,
            alineacion: 'left' 
        });


        const { data: contactosDestinatario } = await this.contactosService.obtenerListado({
            empresa_id: documento.ordenTransporte.empresaDestinatario.id
        }).toPromise<RespuestaApi<Contacto[]>>();
        const infoContactoDestinatario = this.contactosService.telefonosYCorreosDeContactosPorDefecto(contactosDestinatario);
        const orden_destinatario_telefono = infoContactoDestinatario.telefonos.join(' ');
        //const orden_destinatario_email = infoContactoDestinatario.correos[0];
        let nombres = (documento.ordenTransporte.empresaDestinatario.persona.nombres)? documento.ordenTransporte.empresaDestinatario.persona.nombres: '';
        let apellidos = (documento.ordenTransporte.empresaDestinatario.persona.apellidos)? documento.ordenTransporte.empresaDestinatario.persona.apellidos: '';

        let nombreDestinatario = nombres+' '+apellidos
        comprobante.dataTexto.push({
            texto: 'Destinatario: ' + nombreDestinatario,
            alineacion: 'left' 
        });
        comprobante.dataTexto.push({
            texto: 'Telefono destinatario: ' + orden_destinatario_telefono,
            alineacion: 'left' 
        });                
        

        const fecha = moment(documento.fecha_documento).format('DD/MM/YYYY');
        const hora = moment(documento.fecha_documento).format('HH:mm:ss');
        comprobante.dataTexto.push({
            texto: 'Fecha:' + fecha + ' Hora:' + hora
        });

        comprobante.dataTexto.push({
            texto: this.impresoraService.texto('-', totalCaracteres, 'left', '-')
        });
        console.log(documento.tipoDocumento.codigo_sii)


        // DATOS ADICIONALES DEL RECEPTOR
        let linea = '';
        /*if (documento.tipoDocumento.codigo_sii !== 39) {

            const razonSocial = this.titleCase.transform(documento.empresa.persona.razon_social);
            linea = this.impresoraService.texto('Señor(es)', 12, 'right');
            linea += this.impresoraService.texto(razonSocial, totalCaracteres - 14, 'right');
            comprobante.dataTexto.push({
                texto: linea
            });

            const rut = u.numberFormat(Number(documento.empresa.persona.rut), 0, ',', '.');
            linea = this.impresoraService.texto('RUT', 12, 'right');
            linea += ': ' + rut + '-' + documento.empresa.persona.dv;
            comprobante.dataTexto.push({ texto: linea });

            linea = this.impresoraService.texto('Dirección', 12, 'right');
            linea += ': ' + this.titleCase.transform(documento.dte.direccion);
            comprobante.dataTexto.push({ texto: linea });


            linea = this.impresoraService.texto('Ciudad', 12, 'right');
            linea += ': ' + this.titleCase.transform(documento.dte.comuna);
            comprobante.dataTexto.push({ texto: linea });

            const giro = this.titleCase.transform(documento.dte.giro);
            linea = this.impresoraService.texto('Giro', 12, 'right');
            linea += ': ' + this.impresoraService.texto(giro, totalCaracteres - 14, 'right');
            comprobante.dataTexto.push({ texto: linea });

            linea = this.impresoraService.texto('-', totalCaracteres, 'left', '-');
            comprobante.dataTexto.push({ texto: linea });

        }*/


        // SECCION PRODUCTOS
        // Titulo 1
        const producto = this.impresoraService.texto('Producto', 36, 'right');
        linea = this.impresoraService.texto(producto + 'Tracking', totalCaracteres, 'right');
        comprobante.dataTexto.push({ texto: linea });

        // Titulo 2
        const cantidad = this.impresoraService.texto('Cant.', 15, 'right');
        const precio = this.impresoraService.texto('Precio', 24, 'right');
        const total = this.impresoraService.texto('Total', 10, 'right');
        linea = this.impresoraService.texto(cantidad + precio + total, totalCaracteres, 'right');
        comprobante.dataTexto.push({ texto: linea });

        linea = this.impresoraService.texto('-', totalCaracteres, 'left', '-');
        comprobante.dataTexto.push({ texto: linea });

        // Listado Productos
        for (let item of documento.detalleDocumento) {

            // linea 1 (sku, nombre producto)
            const producto = this.impresoraService.texto(item.producto.nombre, 39, 'right');
            const codigo_tracking = (item.ordenTransporteDetalle.codigo_tracking)? this.impresoraService.texto(item.ordenTransporteDetalle.codigo_tracking, 10, 'right') : '';
            linea = this.impresoraService.texto(producto + codigo_tracking, totalCaracteres, 'right');
            comprobante.dataTexto.push({ texto: linea, alineacion: 'left' });


            // linea 2 (cantidad, precio, total)
            const itemCant = this.impresoraService.texto(item.cantidad, 8, 'right');
            const itemPrecio = this.impresoraService.moneda(item.valor_unitario_normal, 12, 'left');
            linea = itemCant + itemPrecio;

            const itemTotal = this.impresoraService.moneda(item.valor_unitario_normal * item.cantidad, 20, 'left');
            const largoColumnas = totalCaracteres - (itemCant + itemPrecio).length;
            const itemLinea2 = this.impresoraService.texto(itemTotal, largoColumnas);
            linea += itemLinea2;

            comprobante.dataTexto.push({ texto: linea, alineacion: 'left' });
            // this.printer.addText(itemLinea2 + '\n');

            // Aplicamos decuento si existe
            if (item.monto_descuento > 0) {
                const itemDescuentoTexto = '*Descuento producto';
                const largoColumnasDesc = totalCaracteres - (itemDescuentoTexto).length;
                const itemDescuento = this.impresoraService.moneda(item.monto_descuento, largoColumnasDesc, 'left', '', '-');
                linea = itemDescuentoTexto + itemDescuento + '\n';
                comprobante.dataTexto.push({ texto: linea, alineacion: 'left' });
            }

            // Se coloca la observación realizada al momento de recepcionar
            if (item.ordenTransporteDetalle.observacion) {
                let texto = '( ' + item.ordenTransporteDetalle.observacion + ' )';
                const itemObservacion = this.impresoraService.textoLargo(texto, totalCaracteres, 'left');
                if (texto.length > totalCaracteres) {
                    itemObservacion.forEach(lineaTexto => {
                        comprobante.dataTexto.push({
                            texto: lineaTexto,
                            alineacion: 'left'
                        });
                    });
                } else {
                    comprobante.dataTexto.push({
                        texto: texto,
                        alineacion: 'left'
                    });
                }
            }

        }

        // comprobante.dataTexto.push({ texto: null, alineacion: 'left' });
        linea = this.impresoraService.texto('-', totalCaracteres, 'left', '-');
        comprobante.dataTexto.push({ texto: linea, alineacion: 'left' });

        // SECCION TOTALES
        const neto = 'Total Neto';
        linea = neto;
        linea += this.impresoraService.moneda(documento.monto_neto, totalCaracteres - neto.length);
        comprobante.dataTexto.push({ texto: linea, alineacion: 'left' });

        // Detalle de impuestos
        for (let i of documento.documentosHasImpuesto) {
            const impuesto = i.nombre;
            linea = impuesto;
            linea += this.impresoraService.moneda(i.monto, totalCaracteres - impuesto.length);
            comprobante.dataTexto.push({ texto: linea, alineacion: 'left' });
        }

        const subtotal = 'Subtotal';
        linea = subtotal;
        linea += this.impresoraService.moneda(documento.monto_total, totalCaracteres - subtotal.length);
        comprobante.dataTexto.push({ texto: linea, alineacion: 'left' });
        comprobante.dataTexto.push({ texto: null, alineacion: 'left' });

        const descuentos = 'Descuentos';
        linea = descuentos;
        linea += this.impresoraService.moneda(documento.monto_descuento, totalCaracteres - descuentos.length);
        comprobante.dataTexto.push({ texto: linea, alineacion: 'left' });

        const redondeo = 'Redondeo';
        linea = redondeo;
        linea += this.impresoraService.moneda(documento.redondeo, totalCaracteres - redondeo.length);
        comprobante.dataTexto.push({ texto: linea, alineacion: 'left' });

        let total2 = 'Total';
        linea = total;
        linea += this.impresoraService.moneda(documento.monto_total_pago, totalCaracteres - total.length);
        comprobante.dataTexto.push({ texto: linea, alineacion: 'left' });

        // Detalle de formas de pago
        comprobante.dataTexto.push({ texto: null, alineacion: 'left' });
        comprobante.dataTexto.push({ texto: 'Formas de pago', alineacion: 'left' });

        for (let p of documento.pagoDocumento) {
            let pago = p.pago.formaPago.descripcion;
            linea = pago;
            linea += this.impresoraService.moneda(p.pago.monto_pago, totalCaracteres - pago.length);
            comprobante.dataTexto.push({ texto: linea, alineacion: 'left' });
        }

        comprobante.dataTexto.push({ texto: null, alineacion: 'left' });

        if (documento.dte != null) {
            comprobante.dataTexto.push({ texto: documento.dte.timbre_electronico, codigoBarra: true });
        }

        // FOOTER
        comprobante.dataTexto.push({ texto: 'Gracias por su preferencia', alineacion: 'center' });
        return comprobante;
    }
}
