import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { Notificaciones } from '../../../interfaces/notificaciones';

@Component({
  selector: 'app-modal-informacion',
  templateUrl: './modal-informacion.component.html',
  styleUrls: ['./modal-informacion.component.scss']
})
export class ModalInformacionComponent implements OnInit, AfterViewInit {

  @ViewChild('modalInformacion', { static: false }) modalTemplate: ElementRef;

  @Input() modalRef: BsModalRef;
  @Input() dataModal: Notificaciones;
  @Output() template = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {
    // console.log(this.dataModal);
    // this.modalRef = this.modalService.show(this.modalInformacion);
  }

  ngAfterViewInit(): void {
    this.template.emit(this.modalTemplate);
  }
  actualizar() {
    location.reload();
  }

}
