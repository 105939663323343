import { Component, OnInit, Input } from '@angular/core';


@Component({
    selector: 'app-msgbox',
    templateUrl: './msgbox.component.html',
})
export class MsgboxComponent implements OnInit {
    @Input() Mensaje: any;
    ngOnInit() {}

    // Mensajes
    public ShowMensaje() {
        let msg: string;
        let css: string;
        let tit: string;

        switch (this.Mensaje.error) {
            case true:
                tit = `Error`;
                msg = this.Mensaje.mensaje;
                css = 'error';
                break;

            case false:
            case undefined:
                tit = `Proceso correcto`;
                msg = this.Mensaje.mensaje;
                css = 'success';
                break;

            default:
                tit = `Bienvenido`;
                msg = `Ud. a ingresado a : ${this.Mensaje.titulo}`;
                css = 'success';
                break;
        }

        switch (this.Mensaje.oper) {
            case 'NoRut':
                tit = `RUT NO VALIDO`;
                msg = 'El RUT no es correcto';
                css = 'error';
                break;

            case 'error':
                tit = `Error`;
                msg = this.Mensaje.mensaje;
                css = 'error';
                break;
        }
        // this.toasterService.pop(css, tit, msg);
    }

    public Show() {
        // this.toasterService.pop(
        //     this.Mensaje.css,
        //     this.Mensaje.tit,
        //     this.Mensaje.msg
        // );
    }

    public close() {
        // this.toasterService.clear();
    }
    // Mensajes
}
