import { BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
export class NotificacionesWsService {
    constructor() {
        this.wsUrl = environment.urlApiNewWs;
        this.notificacionesSubjec = new BehaviorSubject(null);
        this.$notificaciones = this.notificacionesSubjec.asObservable();
    }
    conexionWs() {
        // this.socket = Ws(this.wsUrl, {});
        // this.socket.connect();
        // this.canalesConectados();
    }
    canalesConectados() {
        this.conexionCanalNotificaciones();
    }
    conexionCanalNotificaciones() {
        this.canalNotificaciones = this.socket.subscribe('notificaciones');
        this.canalNotificaciones.on('actualizacion', (data) => {
            console.log('actualizacion recibido', data);
            const newData = Object.assign({}, data, { tipo: 'actualizacion' });
            this.notificacionesSubjec.next(newData);
        });
    }
}
NotificacionesWsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificacionesWsService_Factory() { return new NotificacionesWsService(); }, token: NotificacionesWsService, providedIn: "root" });
