import { Component, OnInit } from '@angular/core';
import { Impresora } from 'app/shared/interfaces/impresora';
import { ImpresionService } from '../../../../services/impresion.service';
import { RootService } from '../../../../services/root.service';
import { ConfiguracionesLocales } from '../../../interfaces/configuraciones-locales';
import * as _ from 'lodash';

@Component({
  selector: 'app-panel-configuraciones',
  templateUrl: './panel-configuraciones.component.html',
  styleUrls: ['./panel-configuraciones.component.scss']
})
export class PanelConfiguracionesComponent implements OnInit {

  impresoras: Impresora[] = [];
  impresoraSeleccionada: string;
  menuAbierto = false;
  configuracionLocal: ConfiguracionesLocales;

  constructor(
    private impresionService: ImpresionService,
    private root: RootService
  ) { }

  ngOnInit() {

    this.impresionService.$impresorasDisponibles.subscribe(r => {
      if (r == null) { return; }
      this.impresoras = r;
      setTimeout(() => {
        this.seleccionarImpresora();
      }, 300);
    })

    this.root.$barraConfiguraciones.subscribe(r => {
      this.menuAbierto = r;
    });

    this.configuracionLocal = this.root.obtenerConfiguracionesLocales();
    if (this.configuracionLocal.impresoraSeleccionada) {
      this.impresoraSeleccionada = this.configuracionLocal.impresoraSeleccionada.id;
      _.remove(this.impresoras, function(i) {
        return i.id=='';
      });
      this.seleccionarImpresora();
      
    } else {
      this.impresoras.push({
        activa: true,
        configuracion_adicional: null,
        id: "",
        ip: "",
        nombre: "Por favor, seleccionar",
        puerto: 8181,
        slug_tipo_impresora: ""
      });
      this.impresoraSeleccionada =''
    }
  }

  cerrar() {
    this.root.barraConfiguracion(false);
  }

  seleccionarImpresora() {
    const impresoraSeleccionada = this.impresoras.find(item => item.id === this.impresoraSeleccionada) || '';
    this.root.guardarConfiguraciones({ impresoraSeleccionada });
    console.log(impresoraSeleccionada)
  }

}
