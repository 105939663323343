import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ConfiguracionesService } from './configuraciones.service';
import { ConfiguracionesModel } from 'app/models/configuraciones.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { RootService } from './root.service';
import { RespuestaApi } from '../shared/interfaces/respuesta-api';
import { LocalStorageService } from 'angular-2-local-storage';
import { ConfiguracionesLocales } from 'app/shared/interfaces/configuraciones-locales';
import { Impresora } from 'app/shared/interfaces/impresora';
import { ToastrService } from 'ngx-toastr';
import { mensajes } from 'app/data/mensajes';
import { ImpresoraService } from './impresora.service';


@Injectable({
  providedIn: 'root'
})
export class ImpresionService {


  headers = new HttpHeaders();
  configuraciones: ConfiguracionesModel;

  private impresorasDisponibles: BehaviorSubject<Impresora[]> = new BehaviorSubject([]);
  public $impresorasDisponibles = this.impresorasDisponibles.asObservable();
  configuracionLocal: ConfiguracionesLocales;
  impresoraSeleccionada: Impresora = null;



  constructor(
    private http: HttpClient,
    private configuracionesService: ConfiguracionesService,
    private root: RootService,
    private localStorageService: LocalStorageService,
    private toastr: ToastrService,
    private impresoraService: ImpresoraService,

  ) {
    this.headers.set('content-type', 'application/json');
    this.headers.set('Access-Control-Allow-Origin', '*');
    this.configuraciones = this.configuracionesService.obtenerVariablesEnMemoria();
  }


  imprimirComprobante(data) {
    return this.http.post(environment.urlApiImpresion + `imprimir`, data, { headers: this.headers });
  }

  impresionRaw(impresora_ip, impresora_puerto, data) {
    return this.http.post(environment.urlApiNew + `impresora/impresion-raw`, {
      impresora_ip: impresora_ip,
      impresora_puerto: impresora_puerto,
      data: data
    });
  }

  configuracion() {
    return this.http.get(environment.urlApiNew + `impresora/configuracion`);
  }

  obtenerConfiguracion() {
    this.configuracion().subscribe((r: RespuestaApi) => {
      this.localStorageService.set('configuracionImpresora', r.data);
    })
  }

  listarImpresoras() {
    return this.http.get(environment.urlApiNew + `impresora`);
  }

  impresoraDisponibles() {
    this.listarImpresoras().subscribe((r: RespuestaApi) => {
      this.impresorasDisponibles.next(r.data);
    }, e => {
      this.root.validaError(e);
    });
  }

  async imprimirData(data: any) {
    console.log(data)


    this.impresoraSeleccionada = this.validarMetodoImpresion();
    if (!this.impresoraSeleccionada) { return false; }
    this.impresoraSeleccionada = this.configuracionLocal.impresoraSeleccionada;

    switch (this.impresoraSeleccionada.slug_tipo_impresora) {
      case 'epson-raw':
        this.impresionRaw(this.impresoraSeleccionada.ip, this.impresoraSeleccionada.puerto, data).subscribe(r => {
          console.log(r);
        }, e => {
          this.root.validaErrorImpresora(e);
        });
        break;
      case 'epson-epos':
        this.impresoraService.conectarImpresora(this.impresoraSeleccionada.ip, this.impresoraSeleccionada.puerto, data);
        break;

      case 'api-impresion':
        this.imprimirComprobante(data).subscribe(r => {

        }, e => {
          this.root.validaErrorImpresora(e);
        })
        break;

      default:
        this.toastr.warning('Método de impresión no configurado');
        break;
    }

  }

  validarMetodoImpresion() {

    this.configuracionLocal = this.root.obtenerConfiguracionesLocales();
    console.log("validarMetodoImpresion() - configuracionLocal", this.configuracionLocal)
    if (!this.configuracionLocal.hasOwnProperty('impresoraSeleccionada')) {
      this.toastr.info(mensajes.confImpresoraPorDefecto, mensajes.tituloInformacion, { positionClass: 'toast-top-center' });
      this.root.barraConfiguracion(true);
    }
    const impresoraSeleccionada = this.configuracionLocal.impresoraSeleccionada;
    return impresoraSeleccionada;
  }

}
