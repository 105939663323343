import { Injectable, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { Usuario } from './models/usuario';
import { MsgboxComponent } from './modshared/mod/msgbox/msgbox.component';
import { AdonisService } from './adonis.service';

declare const loadingDiv: any;
@Injectable()
export class MyglobalService {
    // Mensajes
    @ViewChild(MsgboxComponent, { static: false}) message: MsgboxComponent;

    public user = new BehaviorSubject<any>('');
    public listamenu = new BehaviorSubject<any>('');
    public usx: any;
    public simpleDtOptions: any;
    public simpleDtOptions2: any;

    // Casteo de variable
    getUser = this.user.asObservable();
    getLista = this.listamenu.asObservable();

    constructor(
        public router: Router,
        public localg: LocalStorageService,
        private apiado: AdonisService
    ) {
        // establecemos la configuracion para datatable simples
        this.simpleDtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            retrieve: true,
            // ordering: false,
            language: {
                // url: '//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json',
                url: 'assets/js/datatable/Spanish.json',
                // sProcessing: loadingDiv(false, 'Cargando registros', true)
            },
            columnDefs: [
                {
                    targets: 'no-sort',
                    orderable: false,
                },
            ],
        };
    }

    UpdateUser(usrx) {
        this.user.next(usrx);
    }

    UpdateLista(lst) {
        let lsx: any = '';
        try {
            lsx = JSON.parse(lst);
        } catch (err) {
        } finally {
            this.listamenu.next(lsx);
        }
    }

    IsLogin() {
        // // verificamos si la variable expira existe
        // if (this.localg.get('expira')) {
        //   // verificamos que si la fecha es valida
        //   const expira = Number(this.localg.get('expira'))
        //   const expiraFecha = new Date()
        //   expiraFecha.setTime(expira);

        //   if (expiraFecha < new Date()) {
        //     // esto quiere decir que ya expiro la fecha
        //     this.router.navigate(['login'])
        //     return true
        //   }

        // } else {
        //   console.log('NO Existe EXPIRA');
        //   this.router.navigate(['login'])
        //   return true
        // }

        // Validamos si existe los datos de
        this.usx = this.localg.get('usx');

        console.log(this.usx);
        if (!this.usx) {
            this.router.navigate(['login']);
            return false;
        } else {
            this.UpdateUser(this.usx);
            this.UpdateLista(this.usx.Perfile.permisos);
            return true;
        }
    }

    // Valida accesos al controlador
    validaAcceso(ruta) {
        const menu: any = this.localg.get('menu');
        let menuProcesado = null;
        for (const item of menu.modulos) {
            if (ruta + '/' === item.ruta) {
                menuProcesado = item.menu;
                break;
            }
        }

        const response = {
            menu: menuProcesado,
            permitido: true,
        };

        return response;
    }

    // Permite cerrar la sesion
    public logout() {
        this.localg.remove('usx');

        // Limiar globales
        this.usx = new Usuario();
        const menu = [];
        this.UpdateLista(menu);
        this.UpdateUser(this.usx);
        this.router.navigate(['login']);
    }

    public sincronizar() {
        const user = this.localg.get('usx');
        const data = {
            usuario_id: user['id'],
        };

        this.apiado.Post(data, 'Sincronizacion/orden').subscribe(
            res => {
                alert(res.mensaje);
            },
            err => {
                alert(err.mensaje);
            }
        );
        // console.log(this.message)
        // this.message.Mensaje = {
        //   css: 'error',
        //   tit: 'Advertencia',
        //   msg: `Venta a publico no puede pagar con credito`
        // }
        // this.message.Show();
    }

    escapeRegExp(str) {
        return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
    }

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    }
}
