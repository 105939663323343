export const environment = {
    production: true,
    urlApi: 'http://35.247.204.251/api/mountain-qa/v1/',
    urlApiNew: 'https://api.rarama.cl/',//prueba
    //urlApiNew: 'https://api-desarrollo.rarama.cl/',//produccion
    urlApiNewWs: 'ws://localhost:3333',
    urlApiSincronizador: 'http://localhost:3355/',
    urlApiImpresion: ' http://127.0.0.1:8181/impresion/',
    sucursalDefecto: 'santiago',
    nombreSesion: 'MountainPosImplementos',
    webToken:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjp7InVpZCI6NH0sImlhdCI6MTUwNzg1MzEwOX0.5yUMrFaFRw32lZz_HXvaGzy1_J-MZQn6KSu9425fyaQ',

};