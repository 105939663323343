import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { AccessToken } from '../shared/interfaces/access-token';
import { RootService } from './root.service';

@Injectable()
export class AutenticadorService {
  constructor(
    private router: Router,
    public localStore: LocalStorageService,
    private root: RootService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  
    const access_token: AccessToken = this.localStore.get('access_token') as AccessToken;
    let token = '';
    if (access_token) {
      token = access_token.token;
    }
    
    let request = req;

    if (access_token) {
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${ token }`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {

        if (err.status === 401) {
          this.root.logout();
          this.router.navigateByUrl('/login');
        }

        return throwError( err );

      })
    );
  }
}