
<div id="modal-eliminar" (keyup.enter)="ejecutarFuncion()"   bsModal #ModalEliminar="bs-modal" class="modal fade modal-danger" tabindex="1" role="dialog" aria-labelledby="myModalLabel"
aria-hidden="true">
<div class="modal-dialog " role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{options.modalTitle }}</h4>
      <button type="button" class="close" (click)="ModalEliminar.hide()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      {{ options.modalMsg }}
      
      <br>
      
      <div class="modal-footer">
          <button type="button" (click)="ModalEliminar.hide()" class="btn btn-danger">
            <i class="fa fa-ban"></i>
            Cancelar</button>
          <button type="button" class="btn btn-success" (click)="ejecutarFuncion()">
              <i class="fa fa-check"></i>
            Aceptar</button>
        </div>
        <!-- /.modal-content -->
    </div>

    <!-- /.modal-dialog -->
  </div>
</div>
</div>


<!--  MODAL CONFIRMAR -->
<div id="modal-normal" (keyup.enter)="ejecutarFuncion()"   bsModal #ModalConfirmar="bs-modal" class="modal fade {{options.modalClass }}" tabindex="1" role="dialog" aria-labelledby="myModalLabel"
aria-hidden="true">
<div class="modal-dialog " role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{options.modalTitle }}</h4>
      <button type="button" class="close" (click)="ModalConfirmar.hide()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" [innerHTML]="options.modalMsg">
      <!-- {{ options.modalMsg }} -->
      <!-- /.modal-content -->
    </div>
    <div class="modal-footer">
      <button type="button" (click)="ModalConfirmar.hide()" class="btn btn-danger">
        <i class="fa fa-ban"></i>
        Cancelar</button>
      <button type="button" class="btn btn-success" (click)="ejecutarFuncion()">
          <i class="fa fa-check"></i>
        Aceptar
      </button>
    </div>
    <!-- /.modal-dialog -->
  </div>
</div>
</div>