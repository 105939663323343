import { NgModule } from '@angular/core';

import { NumberFormatPipe } from '../pipes/number-format.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';

import {CommonModule} from '@angular/common';
import { CurrencyFormatPipe } from './currency-format.pipe';
@NgModule({
    declarations: [NumberFormatPipe, SafeHtmlPipe, CurrencyFormatPipe], // <---
    imports: [],
    exports: [NumberFormatPipe, SafeHtmlPipe, CurrencyFormatPipe], // <---
})
export class MainPipe {
    static forRoot() {
        return {
            ngModule: MainPipe,
            providers: [],
        };
    }
}
