import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "angular-2-local-storage";
export class CajasUsuarioService {
    constructor(http, localStorage) {
        this.http = http;
        this.localStorage = localStorage;
        // totales
        this.cajaUsuarioSubject = new BehaviorSubject('');
        this.$cajaUsuario = this.cajaUsuarioSubject.asObservable();
        const detalleCaja = this.obtenerSesionCaja();
        this.cajaUsuarioSubject.next(detalleCaja);
    }
    crear(data) {
        return this.http.post(environment.urlApiNew + 'cajas-usuario', data);
    }
    guardarSesionCaja(data) {
        this.localStorage.set('caja', data);
        this.cajaUsuarioSubject.next(data);
    }
    obtenerSesionCaja() {
        return this.localStorage.get('caja');
    }
    limpiarSesionCaja() {
        this.cajaUsuarioSubject.next(null);
        return this.localStorage.remove('caja');
    }
    obtenerInformacionCierre(id) {
        return this.http.get(environment.urlApiNew + `cajas-usuario/informacion-cierre/${id}`);
    }
    validarCierreCaja(cajaUsuarioId, data) {
        return this.http.post(`${environment.urlApiNew}cajas-usuario/validar-cierre/${cajaUsuarioId}`, data);
    }
    cerrarCaja(cajaUsuarioId, data) {
        return this.http.post(`${environment.urlApiNew}cajas-usuario/cerrar-caja/${cajaUsuarioId}`, data);
    }
    resumenCierre(cajaUsuarioId) {
        return this.http.get(`${environment.urlApiNew}cajas-usuario/resumen-cierre/${cajaUsuarioId}`);
    }
}
CajasUsuarioService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CajasUsuarioService_Factory() { return new CajasUsuarioService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStorageService)); }, token: CajasUsuarioService, providedIn: "root" });
