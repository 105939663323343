import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';
import * as u from 'underscore.string';
import { environment } from 'environments/environment';
import { mensajes } from '../data/mensajes';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-toastr";
import * as i3 from "./configuraciones.service";
import * as i4 from "@angular/common";
import * as i5 from "./root.service";
export class ImpresoraService {
    constructor(http, toastr, configuracionesService, titleCase, root) {
        // this.conexionImpresora();
        this.http = http;
        this.toastr = toastr;
        this.configuracionesService = configuracionesService;
        this.titleCase = titleCase;
        this.root = root;
        this.ePosDev = new epson.ePOSDevice();
        this.printer = null;
        this.deviceId = 'local_printer';
        this.impresoraSubject = new BehaviorSubject(null);
        this.$impresora = this.impresoraSubject.asObservable();
        this.impresoraConectadaSubject = new BehaviorSubject(false);
        this.$impresoraConectada = this.impresoraConectadaSubject.asObservable();
        this.impresoraConectandoSubject = new BehaviorSubject(false);
        this.$impresoraConectando = this.impresoraConectandoSubject.asObservable();
        this.impresoraDataSubject = new BehaviorSubject(null);
        this.$impresoraData = this.impresoraDataSubject.asObservable();
        this.caracterRelleno = ' ';
        this.totalCaracteres = 46;
        this.totalCaracteresFuenteA = 46;
        this.totalCaracteresFuenteB = 56;
    }
    imprimirComprobante(id) {
        return this.http.get(environment.urlApiNew + `impresora/documento/${id}`);
    }
    imprimirOrdenTransporte(id) {
        return this.http.get(environment.urlApiNew + `impresora/orden-transporte/${id}`);
    }
    imprimirCargoReciboConforme(id) {
        return this.http.get(environment.urlApiNew + `impresora/cargo-recibo-conforme/${id}`);
    }
    // conectarImpresora(ipImpresora = '192.168.1.150', puerto = '8008') {
    //   console.log('Se inicia la conexión');
    //   this.impresoraConectandoSubject.next(true);
    //   this.ePosDev.timeout = 5000;
    //   this.ePosDev.connect(ipImpresora, puerto, (estado) => {
    //     this.callback_connect(estado);
    //   });
    //   // console.log(this.ePosDev);
    // }
    conectarImpresora(ipImpresora, puerto, data = null) {
        if (!ipImpresora && !puerto) {
            this.toastr.error('Falta definir la ip y/o puerto de las impresora');
            return;
        }
        this.impresoraConectandoSubject.next(true);
        this.ePosDev.timeout = 5000;
        this.ePosDev.connect(ipImpresora, puerto, (estado) => {
            this.callback_connect(estado, data);
        });
        // console.log(this.ePosDev);
    }
    desconectar() {
        console.log('Se desconecta la impresora');
        this.ePosDev.deleteDevice(this.printer, (r) => {
            console.log(r);
        });
        this.ePosDev.disconnect();
    }
    callback_connect(resultConnect, data) {
        console.log(resultConnect);
        const options = { crypto: false, buffer: false };
        if ((resultConnect === 'OK') || (resultConnect === 'SSL_CONNECT_OK')) {
            console.log('impresora conectada');
            this.ePosDev.createDevice(this.deviceId, this.ePosDev.DEVICE_TYPE_HYBRID_PRINTER2, options, (deviceObj, errorCode) => {
                this.callbackImpresora(deviceObj, errorCode, data);
            });
        }
        else {
            // Displays error messages
            console.log('impresora desconectada');
            this.impresoraConectandoSubject.next(false);
            this.impresoraConectadaSubject.next(false);
            this.toastr.error(mensajes.impresoraNoConectada, mensajes.tituloError);
        }
    }
    /**
     * Entra aca con el resultado de la operacion de la impresora, cualquiera sea el evento, y lo notifica por un observable
     * @param deviceObj - objeto impresora
     * @param errorCode - codigo de error
     */
    callbackImpresora(deviceObj, errorCode, data) {
        this.impresoraConectandoSubject.next(false);
        // console.log(deviceObj, errorCode);
        if (deviceObj === null) {
            this.toastr.error(mensajes.impresoraEnUso, mensajes.tituloError);
            console.log('deviceObj', errorCode);
            return;
        }
        this.printer = deviceObj;
        this.impresoraConectadaSubject.next(true);
        this.impresoraDataSubject.next(data);
        this.printer.onreceive = (response) => {
            // console.log(response)
            if (response.success) {
                // console.log('objeto impresora recibido con exito');
            }
            else {
                console.log('error callback_createDevice');
            }
            this.impresoraSubject.next(response);
        };
    }
    escanearCheque(tiempoEspera = 10000) {
        // console.log(this.printer)
        const font = this.printer.FONT_CMC7;
        const timeout = tiempoEspera;
        this.printer.readMicrData(true, font, Number(timeout));
    }
    expulsarCheque() {
        this.printer.ejectPaper();
    }
    cancelarInsercion() {
        this.printer.cancelInsertion();
    }
    comprobanteDeVenta(documento) {
        console.log(this.printer);
        return new Observable(obs => {
            // obtenerVariablesEnMemoria
            this.configuraciones = this.configuracionesService.obtenerVariablesEnMemoria();
            this.printer.selectPaperType(this.printer.PAPERTYPE_RECEIPT);
            this.cabeceraComprobante(documento);
            this.cabeceraDatosFacturacion(documento);
            this.productosComprobante(documento);
            this.totalesComprobante(documento);
            this.pieComprobante(documento);
            this.printer.addText('\n\n');
            // const mensaje = this.printer.getMessage();
            // console.log(mensaje)
            this.printer.addCut(this.printer.CUT_FEED);
            this.printer.sendData();
            obs.next(true);
        });
        // this.cabeceraComprobante(documento);
        // this.cabeceraDatosFacturacion(documento);
        // this.productosComprobante(documento);
        // this.totalesComprobante(documento);
    }
    cabeceraComprobante(documento) {
        this.printer.selectPaperType(this.printer.PAPERTYPE_RECEIPT);
        this.printer.ReceiptPrinter.addTextStyle(false, false, false, this.printer.COLOR_1);
        // datos cabecera empresa emite
        this.printer.ReceiptPrinter.addTextAlign(this.printer.ReceiptPrinter.ALIGN_CENTER);
        this.printer.ReceiptPrinter.addText(documento.tipoDocumento.descripcion);
        this.printer.addText(' N°' + documento.folio + '\n');
        this.printer.ReceiptPrinter.addTextStyle(false, false, true, this.printer.ReceiptPrinter.COLOR_1);
        this.printer.addText(this.configuraciones.facturacion_emisor_razon_social + '\n');
        this.printer.ReceiptPrinter.addTextStyle(false, false, false, this.printer.ReceiptPrinter.COLOR_1);
        // Rut
        const rutCompleto = this.root.procesarRut(this.configuraciones.facturacion_emisor_rut);
        const rut = u.numberFormat(Number(rutCompleto.rut), 0, ',', '.');
        this.printer.addText(rut + '-' + rutCompleto.dv + '\n');
        this.printer.addText('\n');
        this.printer.ReceiptPrinter.addTextAlign(this.printer.ReceiptPrinter.ALIGN_LEFT);
        this.printer.addText(this.configuraciones.facturacion_emisor_direccion + '\n');
        this.printer.addText(this.configuraciones.facturacion_emisor_ciudad + '\n');
        // // fecha y hora
        const fecha = moment(documento.fecha_documento).format('DD/MM/YYYY');
        const hora = moment(documento.fecha_documento).format('HH:mm:ss');
        this.printer.addText('Fecha: ');
        this.printer.addText(fecha);
        this.printer.addText(' Hora:');
        this.printer.addText(hora);
        this.printer.addText('\n');
        this.printer.addText(this.texto('-', this.totalCaracteres, 'left', '-'));
    }
    cabeceraDatosFacturacion(documento) {
        if (documento.tipoDocumento.codigo_sii !== 33) {
            return true;
        }
        this.printer.ReceiptPrinter.addTextAlign(this.printer.ReceiptPrinter.ALIGN_LEFT);
        this.printer.addText(this.texto('Señor(es)', 12, 'right'));
        const razonSocial = this.titleCase.transform(documento.empresa.persona.razon_social);
        this.printer.addText(": " + this.texto(razonSocial, this.totalCaracteres - 14, 'right'));
        this.printer.addText('\n');
        const rut = u.numberFormat(Number(documento.empresa.persona.rut), 0, ',', '.');
        this.printer.addText(this.texto('RUT', 12, 'right'));
        this.printer.addText(": " + rut + '-' + documento.empresa.persona.dv);
        this.printer.addText('\n');
        this.printer.addText(this.texto('Dirección', 12, 'right'));
        this.printer.addText(": " + this.titleCase.transform(documento.dte[0].direccion));
        this.printer.addText('\n');
        this.printer.addText(this.texto('Ciudad', 12, 'right'));
        this.printer.addText(": " + this.titleCase.transform(documento.dte[0].comuna));
        this.printer.addText('\n');
        // this.printer.addText(this.texto('Telefono', 12, 'right'));
        // this.printer.addText(": " + documento.empresa.persona.razon_social);
        // this.printer.addText('\n');
        // giro
        const giro = this.titleCase.transform(documento.dte[0].giro);
        this.printer.addText(this.texto('Giro', 12, 'right'));
        this.printer.addText(": " + this.texto(giro, this.totalCaracteres - 14, 'right'));
        this.printer.addText(this.texto('-', this.totalCaracteres, 'left', '-'));
    }
    productosComprobante(documento) {
        // Titulo 1
        const sku = this.texto('SKU', 15, 'right');
        const titulo1 = this.texto(sku + 'Producto', this.totalCaracteres, 'right');
        this.printer.addText(titulo1, this.totalCaracteres + '\n');
        // Titulo 2
        const cantidad = this.texto('Cant.', 15, 'right');
        const precio = this.texto('Precio', 24, 'right');
        const total = this.texto('Total', 10, 'right');
        let titulo2 = this.texto(cantidad + precio + total, this.totalCaracteres, 'right');
        this.printer.addText(titulo2 + '\n');
        this.printer.addText(this.texto('-', this.totalCaracteres, 'left', '-'));
        // Listado Productos
        for (let item of documento.detalleDocumento) {
            this.printer.ReceiptPrinter.addTextAlign(this.printer.ReceiptPrinter.ALIGN_LEFT);
            // linea 1 (sku, nombre producto)
            const itemSku = this.texto(item.codigo_producto, 15, 'right');
            const itemLinea1 = this.texto(itemSku + item.producto.nombre, this.totalCaracteres, 'right');
            this.printer.addText(itemLinea1 + '\n');
            // linea 2 (cantidad, precio, total)
            this.printer.ReceiptPrinter.addTextAlign(this.printer.ReceiptPrinter.ALIGN_RIGHT);
            const itemCant = this.texto(item.cantidad, 8, 'right');
            const itemPrecio = this.moneda(item.valor_unitario_normal, 12, 'left');
            this.printer.addText(itemCant + itemPrecio);
            const itemTotal = this.moneda(item.valor_unitario_normal * item.cantidad, 20, 'left');
            let largoColumnas = this.totalCaracteres - (itemCant + itemPrecio).length;
            const itemLinea2 = this.texto(itemTotal, largoColumnas);
            this.printer.addText(itemLinea2 + '\n');
            // Aplicamos decuento si existe
            if (item.monto_descuento > 0) {
                const itemDescuentoTexto = '*Descuento producto';
                let largoColumnasDesc = this.totalCaracteres - (itemDescuentoTexto).length;
                const itemDescuento = this.moneda(item.monto_descuento, largoColumnasDesc, 'left', '', '-');
                this.printer.addText(itemDescuentoTexto + itemDescuento + '\n');
            }
        }
        this.printer.addText('\n');
        this.printer.addText(this.texto('-', this.totalCaracteres, 'left', '-'));
    }
    totalesComprobante(documento) {
        this.printer.ReceiptPrinter.addTextAlign(this.printer.ReceiptPrinter.ALIGN_RIGHT);
        const neto = 'Total Neto';
        this.printer.addText(neto);
        this.printer.addText(this.moneda(documento.monto_neto, this.totalCaracteres - neto.length));
        this.printer.addText('\n');
        // Detalle de impuestos
        for (let i of documento.documentosHasImpuesto) {
            let impuesto = i.nombre;
            this.printer.addText(impuesto);
            this.printer.addText(this.moneda(i.monto, this.totalCaracteres - impuesto.length));
            this.printer.addText('\n');
        }
        const subtotal = 'Subtotal';
        this.printer.addText(subtotal);
        this.printer.addText(this.moneda(documento.monto_total, this.totalCaracteres - subtotal.length));
        this.printer.addText('\n\n');
        const descuentos = 'Descuentos';
        this.printer.addText(descuentos);
        this.printer.addText(this.moneda(documento.monto_descuento, this.totalCaracteres - descuentos.length));
        this.printer.addText('\n');
        const redondeo = 'Redondeo';
        this.printer.addText(redondeo);
        this.printer.addText(this.moneda(documento.redondeo, this.totalCaracteres - redondeo.length));
        this.printer.addText('\n');
        const total = 'Total';
        this.printer.addText(total);
        this.printer.addText(this.moneda(documento.monto_total_pago, this.totalCaracteres - total.length));
        this.printer.addText('\n');
        // Detalle de formas de pago
        this.printer.addText('\n');
        this.printer.ReceiptPrinter.addTextAlign(this.printer.ReceiptPrinter.ALIGN_LEFT);
        this.printer.addText('Formas de pago \n');
        this.printer.ReceiptPrinter.addTextAlign(this.printer.ReceiptPrinter.ALIGN_RIGHT);
        for (let p of documento.pagoDocumento) {
            let pago = p.pago.formaPago.descripcion;
            this.printer.addText(pago);
            this.printer.addText(this.moneda(p.pago.monto_pago, this.totalCaracteres - pago.length));
            this.printer.addText('\n');
        }
        this.printer.addText('\n');
    }
    pieComprobante(documento) {
        this.printer.ReceiptPrinter.addTextAlign(this.printer.ReceiptPrinter.ALIGN_CENTER);
        this.printer.addSymbol(documento.dte[0].mensaje_retorno.timbre_electronico, this.printer.SYMBOL_PDF417_STANDARD, this.printer.level_1, 2, 5);
        this.printer.addText('\n');
        this.printer.addText('Timbre Electrónico SII');
        this.printer.addText('\n');
        this.printer.addText('Gracias por su preferencia');
    }
    texto(str, largo = this.totalCaracteres, dir = 'left', caracterRelleno = this.caracterRelleno) {
        str = this.cortarTexto(str, largo);
        return u.pad(str, largo, caracterRelleno, dir);
    }
    textoLargo(str, largo = this.totalCaracteres, dir = 'left', caracterRelleno = this.caracterRelleno) {
        let strOriginal = str;
        if (strOriginal.length > largo) {
            /*let cantLineas = strOriginal.length % largo;
            let lineas = []
      
            for (let index = 1; index == cantLineas; index++) {
              
              lineas.push();
      
            }*/
            return u.chop(str, largo, caracterRelleno, dir);
        }
        else {
            str = this.cortarTexto(str, largo);
            return u.pad(str, largo, caracterRelleno, dir);
        }
    }
    moneda(str, largo = this.totalCaracteres, dir = 'left', caracterRelleno = this.caracterRelleno, signo = '') {
        str = signo + '$' + u.numberFormat(str, 0, ',', '.');
        return u.pad(str, largo, caracterRelleno, dir);
    }
    cortarTexto(str, largo) {
        let texto = String(str);
        return texto.substring(0, largo);
    }
}
ImpresoraService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImpresoraService_Factory() { return new ImpresoraService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ToastrService), i0.ɵɵinject(i3.ConfiguracionesService), i0.ɵɵinject(i4.TitleCasePipe), i0.ɵɵinject(i5.RootService)); }, token: ImpresoraService, providedIn: "root" });
