<ng-template #modalInformacion>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Información</h4>
        <button type="button" class="close float-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-pagar" [innerHTML]="dataModal.mensaje">

    </div>

    <div class="modal-footer">

        <ng-container *ngIf="dataModal.tipo=='actualizacion'">
            <button type="button" class="btn btn-outline-primary" (click)="modalRef.hide()">
                <i class="fa fa-ban"></i>
                Mas tarde</button>

            <button type="button" class="btn btn-primary" (click)="actualizar()">
                <i class="fa fa-save"></i>
                Actualizar ahora
            </button>
        </ng-container>

    </div>

</ng-template>