import { Injectable } from '@angular/core';
import { mensajes } from 'app/data/mensajes';
import { ImpresoraZebraConexion } from 'app/shared/interfaces/impresora';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

declare var BrowserPrint: any;

@Injectable({
  providedIn: 'root'
})
export class ImpresoraZebraService {

  selected_device;
  devices = [];

  repuestaImpresora: ImpresoraZebraConexion = {
    conectada: null,
    accion: 'conexion',
    mensaje: null,
    data: null
  }

  private impresoraSubject: BehaviorSubject<ImpresoraZebraConexion> = new BehaviorSubject(this.repuestaImpresora);
  public $impresoraZebra = this.impresoraSubject.asObservable();
  _this = this;

  constructor(private toastr: ToastrService) {

  }

  conexion(mostrarMensajes = true) {
    const _this = this;
    BrowserPrint.getDefaultDevice("printer", function (device) {
      console.log(device);
      // console.log(_this.devices);

      _this.selected_device = device;
      // _this.devices.push(device);
      _this.repuestaImpresora.conectada = false;
      _this.repuestaImpresora.data = device;
      // console.log(_this.devices);

      BrowserPrint.getLocalDevices(function (device_list) {
        _this.selected_device = device_list.printer[0];
        console.log(_this.selected_device)
        _this.repuestaImpresora.conectada = true;
        _this.impresoraSubject.next(_this.repuestaImpresora);

      });

    }, e => {
      console.log(e);
      this.repuestaImpresora.conectada = false;
      if (mostrarMensajes) {
        this.toastr.error('Ha ocurrido un error al conectar con la impresora de ticket', mensajes.tituloError)
      }
      this.impresoraSubject.next(this.repuestaImpresora);
    });
  }

  imprimir(string) {
    console.log(string)
    console.log(this.selected_device);
    const _this = this;
    this.selected_device.send(string, undefined, (e)=>{
      _this.toastr.error(e, mensajes.tituloError);
    });

  }


}
