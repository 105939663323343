import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Producto, ProductoPosListado } from '../shared/interfaces/producto';
import { RespuestaApi } from 'app/shared/interfaces/respuesta-api';
import { NumerosUtils } from 'app/shared/utils/numeros';
import { ConfiguracionesService } from './configuraciones.service';
import { Impuesto } from 'app/shared/interfaces/impuesto';
import { ProductoSimple } from 'app/shared/interfaces/producto-simple';

@Injectable()
export class ProductosService {

  constructor(
    private http: HttpClient,
    private numeros: NumerosUtils,
    private configuracionesService: ConfiguracionesService
  ) { }

  buscarProductoSimple(texto, params = {}) {
    return this.http.get<RespuestaApi<ProductoSimple[]>>(
      environment.urlApiNew + 'Productos',
      { params: { formato: 'simple', buscar: texto, ...params } }
    );
  }

  buscarProducto(id, params = {}) {
    return this.http.get<RespuestaApi<Producto>>(environment.urlApiNew + `Productos/${id}`, { params });
  }

  crear(producto: Producto) {
    return this.http.post(environment.urlApiNew + 'Productos', producto);
  }

  actualizar(producto: Producto) {
    return this.http.put(environment.urlApiNew + 'Productos', producto);
  }

  productosListado(params) {
    return this.http.get<RespuestaApi<Producto[]>>(environment.urlApiNew + 'Productos', { params });
  }

  obtenerPrecioEscala(params){
    return this.http.get(environment.urlApiNew + 'Productos/obtener-precio-escala', { params });
  }

  eliminarProducto(id: string) {
    console.log('servicio eliminar producto');
    console.log(id);
    return this.http.delete(environment.urlApiNew + `Productos/BorrarImagen/${id}`);
  }

  /**
   * Agrega o descuenta los impuestos al precio de un producto
   * 
   *  @param impuestos_incluido_override Sobreescribe variable de configuración general_impuestos_incluido.
   *  Si incluye impuestos, se calcula el neto, si no, se usa `precio` como el neto
   */
  calcularImpuesto(precio: number, productoImpuesto: Impuesto[], impuestos_incluido_override = false) {
    if (impuestos_incluido_override || this.configuracionesService.obtenerVariablesEnMemoria().general_impuestos_incluido) {
      return this.calcularConImpuestoIncluidos(precio, productoImpuesto);
    } else {
      return this.calcularImpuestosConNetos(precio, productoImpuesto);
    }
  }

  /**
   * Agrega los impuestos al precio del producto
   */
  private calcularImpuestosConNetos(precioOriginal: number, productoImpuesto: Impuesto[]) {
    let totalImpuestos = 0;

    productoImpuesto.forEach(impuesto => {
      const factor = Number(impuesto.factor);
      impuesto.valorImpuestoProducto = precioOriginal * (factor / 100);
      totalImpuestos += impuesto.valorImpuestoProducto;
    });
    const valorImpuestosUnitario = this.numeros.convertirEntero(totalImpuestos);
    const precionConImpuesto = this.numeros.convertirEntero(totalImpuestos + precioOriginal);
    const precio = precionConImpuesto;

    return {
      precio,
      precioNeto: precioOriginal,
      valorImpuestosUnitario,
      productoImpuesto
    }
  }

  /**
   * calcula el valor neto del precio del producto y luego obtiene los impuestos
   */
  private calcularConImpuestoIncluidos(precioOriginal: number, productoImpuesto: Impuesto[]) {
    let totalImpuestos = 0;
    let precioNeto = 0;
    let porcentajeTotalImpuestos = 0;

    productoImpuesto.forEach(impuesto => {
      const factor = Number(impuesto.factor);
      porcentajeTotalImpuestos += factor;
    });

    precioNeto = precioOriginal / (1 + (porcentajeTotalImpuestos / 100));
    precioNeto = this.numeros.convertirEntero(precioNeto);


    productoImpuesto.forEach(impuesto => {
      const factor = Number(impuesto.factor);
      impuesto.valorImpuestoProducto = precioNeto * (factor / 100);
      totalImpuestos += impuesto.valorImpuestoProducto;
    });
    const valorImpuestosUnitario = this.numeros.convertirEntero(totalImpuestos);
    const precionConImpuesto = this.numeros.convertirEntero(totalImpuestos + precioNeto);
    const precio = precionConImpuesto;

    return {
      precio,
      precioNeto,
      valorImpuestosUnitario,
      productoImpuesto
    };

    // item.valorImpuestosUnitario = this.numeros.convertirEntero(totalImpuestos);
    // const precionConImpuesto = this.numeros.convertirEntero(totalImpuestos + precioOriginal);
    // item.precio = precionConImpuesto;
  }
}
