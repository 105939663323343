/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-sesion-expirada.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modal-sesion-expirada.component";
import * as i3 from "ngx-bootstrap/modal";
import * as i4 from "../../../../services/configuraciones.service";
var styles_ModalSesionExpiradaComponent = [i0.styles];
var RenderType_ModalSesionExpiradaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalSesionExpiradaComponent, data: {} });
export { RenderType_ModalSesionExpiradaComponent as RenderType_ModalSesionExpiradaComponent };
export function View_ModalSesionExpiradaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-header align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sesi\u00F3n a punto de terminar "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close float-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.continuar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Su sesion se cerrara en "])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [["id", "countdown"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" segundos"])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "modal-footer d-inline-block right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "btn btn-primary  mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.continuar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "fa fa-check"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Continuar "]))], null, null); }
export function View_ModalSesionExpiradaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-sesion-expirada", [], null, null, null, View_ModalSesionExpiradaComponent_0, RenderType_ModalSesionExpiradaComponent)), i1.ɵdid(1, 114688, null, 0, i2.ModalSesionExpiradaComponent, [i3.BsModalRef, i4.ConfiguracionesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalSesionExpiradaComponentNgFactory = i1.ɵccf("app-modal-sesion-expirada", i2.ModalSesionExpiradaComponent, View_ModalSesionExpiradaComponent_Host_0, {}, {}, []);
export { ModalSesionExpiradaComponentNgFactory as ModalSesionExpiradaComponentNgFactory };
