import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) {

  }

  iniciarSesion(data) {
    return this.http.post(environment.urlApiNew + 'login/iniciar-session', data);

  }

  recuperarClave(data) {
    return this.http.post(environment.urlApiNew + 'login/recuperar-clave', data);

  }
  
  validarCodigoRecuperacion(data) {
    return this.http.post(environment.urlApiNew + 'login/codigo-recuperacion', data);

  }

}
