import { Injectable, EventEmitter } from '@angular/core';
import { Observer } from 'rxjs';

@Injectable()
export class ToastControlService {
    private emisor = new EventEmitter<any>();

    public mostrarMensaje(titulo: string, mensaje: string = ' ') {
        this.emisor.emit({
            type: 'success',
            title: titulo,
            body: mensaje,
        });
    }

    public mostrarMensajeError(titulo: string, mensaje: string = ' ') {
        this.emisor.emit({
            type: 'error',
            title: titulo,
            body: mensaje,
        });
    }

    public mostrarMensajeAlerta(titulo: string, mensaje: string = ' ') {
        this.emisor.emit({
            type: 'warning',
            title: titulo,
            body: mensaje,
        });
    }

    public subscribirObservable(observer: Observer<any>) {
        // TODO: guardar subscripiones y desuscribirse en `ngOnDestroy`
        return this.emisor.subscribe(
            observer.next,
            observer.error,
            observer.complete
        );
    }
}
