import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { RootService } from '../services/root.service';
import { Usuario } from '../shared/interfaces/usuario';
import { Modulo } from '../shared/interfaces/modulo';

@Component({
    selector: 'app-dashboard',
    templateUrl: './full-layout.component.html',
})
export class FullLayoutComponent implements OnInit {
    public get mostrarSincronizacion() {
        return environment.sucursalDefecto === 'isla';
    }

 
    public listamenu: any = '';
    public menu: Modulo[];
    usuario: Usuario;

    constructor(
        public localStorage: LocalStorageService,
        public router: Router,
        public root: RootService
    ) { }

    public disabled: boolean = false;
    public status: { isopen: boolean } = { isopen: false };

    public toggled(open: boolean): void {
        console.log('Dropdown is now: ', open);
    }

    public toggleDropdown($event: MouseEvent): void {
        $event.preventDefault();
        $event.stopPropagation();
        this.status.isopen = !this.status.isopen;
    }

    ngOnInit(): void {
        this.usuario =  this.localStorage.get('usuario');
        this.menu = this.usuario.modulos;
    }
}
