import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ContactosService {
    constructor(http) {
        this.http = http;
    }
    obtenerListado(query) {
        return this.http.get(environment.urlApiNew + 'contactos', { params: query });
    }
    obtenerListadoTipoContactos(query = {}) {
        return this.http.get(environment.urlApiNew + 'tipo-contactos', { params: query });
    }
    telefonosYCorreosDeContactosPorDefecto(contactos) {
        const telefonos = [];
        const correos = [];
        if (Array.isArray(contactos) && contactos.length > 0) {
            const contacto = contactos
                .filter(c => c.tipoContacto)
                .find(c => c.tipoContacto.nombre.toLowerCase() == 'defecto') || contactos[0];
            if (Array.isArray(contacto.contactoTelefono) && contacto.contactoTelefono.length > 0) {
                telefonos.push(contacto.contactoTelefono.map(t => t.telefono || t.celular));
            }
            if (Array.isArray(contacto.contactoCorreo) && contacto.contactoCorreo.length > 0) {
                correos.push(...contacto.contactoCorreo.map(c => c.email));
            }
        }
        return { telefonos, correos };
    }
}
ContactosService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContactosService_Factory() { return new ContactosService(i0.ɵɵinject(i1.HttpClient)); }, token: ContactosService, providedIn: "root" });
