export class ConfiguracionesModel {
  // tslint:disable: variable-name
  tienda?: any;
  nombre?: any;

  categoria_uno?: any;
  categoria_dos?: any;
  categoria_tres?: any;
  nombre_marca?: any;
  facturador?: any;
  logo_header?: any;
  logo_loading?: any;
  precio_minimo?: any;
  nombre_numero_documento?: string;
  nombre_num_documento?: string;
  cierre_caja_resumen_amigable?: boolean;

  // POS
  pos_integrado?: any;
  pos_tipo_cajas_usuario?: any;
  pos_max_devolucion_efectivo?: any;
  pos_cheque_dias_vigencia?: any;
  pos_cheque_dias_por_vencer?: any;
  pos_cheque_verificar?: boolean;
  pos_cheque_verificador?: string;
  pos_abrir_caja_redireccion?: boolean;
  pos_mostrar_subtitulo_producto?: boolean;
  pos_mostrar_codigo_interno?: boolean;
  pos_cargar_monto_pago?: boolean;
  pos_metodo_impresion?: string;
  pos_descontar_stock_ventas?: boolean;
  pos_imprimir_comprobante_automaticamente?: boolean;
  pos_mostrar_campos_minimos_pago_cheque?: boolean;

  // FACTURACION
  facturacion_habilitar_facturacion_electronica?: boolean;
  facturacion_emisor_razon_social?: string;
  facturacion_emisor_rut?: string;
  facturacion_emisor_telefono?: string;
  facturacion_emisor_direccion?: string;
  facturacion_emisor_ciudad?: string;

  facturacion_folio_dte_34?: string;
  facturacion_folio_dte_39?: string;
  facturacion_pie_pagina?: string;

  // GENERAL

  general_impuestos_incluido?: boolean;
  general_ancho_caracteres_impresion?: number;
  general_codigo_zebra?: string;

  //CARGO
  cargo_cupo_maximo_declarado?: number;
  cargo_policitcas_gestion_comprobante?: string;

  // SEGURIDAD
  sesion_tiempo_modal?: number;
  sesion_tiempo_actualizar_token?: number;
  sesion_tiempo_modal_cerrado?: number;

  constructor() {
    this.tienda = "tienda";
    this.categoria_uno = "Categoria uno";
    this.categoria_dos = "Categoria dos";
    this.categoria_tres = "Categoria tres";
    this.precio_minimo = 0;
    this.nombre_marca = "Marca";
    this.logo_header = "assets/img/logo/logo_defecto.png";
    this.logo_loading = "assets/img/logo/logo_defecto.png";
    this.nombre_num_documento = "Folio";
    this.nombre_numero_documento = "Número documento";

    // POS
    this.pos_integrado = "false";
    this.pos_max_devolucion_efectivo = 0;
    this.pos_cheque_dias_vigencia = 60;
    this.pos_cheque_dias_por_vencer = 3;
    this.pos_cheque_verificar = true;
    this.pos_cheque_verificador = "orsan";
    this.pos_abrir_caja_redireccion = true;
    this.cierre_caja_resumen_amigable = true;
    this.pos_tipo_cajas_usuario = "";
    this.pos_mostrar_subtitulo_producto = true;
    this.pos_mostrar_codigo_interno = true;
    this.pos_cargar_monto_pago = true;
    this.pos_metodo_impresion = "epson";
    this.pos_descontar_stock_ventas = false;
    this.pos_imprimir_comprobante_automaticamente = true;

    // FACTURACION
    this.facturacion_habilitar_facturacion_electronica = false;
    this.facturacion_folio_dte_34 = null;
    this.facturacion_folio_dte_39 = null;

    // GENERAL
    this.general_impuestos_incluido = true;
    this.general_ancho_caracteres_impresion = 46;

    this.general_codigo_zebra = "";
    this.facturacion_pie_pagina = "";

    this.sesion_tiempo_modal = 720;// 12*60 equivalente de horas en minutos para el tiempo en que aparece la ventana modal
    this.sesion_tiempo_actualizar_token = 10;
    this.sesion_tiempo_modal_cerrado = 0.5;
  }
}
