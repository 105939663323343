import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { RespuestaApi } from '../shared/interfaces/respuesta-api';
import { Contacto } from 'app/shared/interfaces/contacto';

@Injectable({
  providedIn: 'root'
})
export class ContactosService {

  constructor(
    private http: HttpClient
  ) { }

  obtenerListado(query): Observable<RespuestaApi> {
    return this.http.get(environment.urlApiNew + 'contactos', { params: query })
  }

  obtenerListadoTipoContactos(query = {}): Observable<RespuestaApi> {
    return this.http.get(environment.urlApiNew + 'tipo-contactos', { params: query })
  }
  
  telefonosYCorreosDeContactosPorDefecto(contactos: Contacto[]) {
    const telefonos = [];
    const correos = [];

    if (Array.isArray(contactos) && contactos.length > 0) {
      const contacto = contactos
        .filter(c => c.tipoContacto)
        .find(c => c.tipoContacto.nombre.toLowerCase() == 'defecto') || contactos[0];

      if (Array.isArray(contacto.contactoTelefono) && contacto.contactoTelefono.length > 0) {
        telefonos.push(contacto.contactoTelefono.map(t => t.telefono || t.celular));
      }

      if (Array.isArray(contacto.contactoCorreo) && contacto.contactoCorreo.length > 0) {
        correos.push(...contacto.contactoCorreo.map(c => c.email));
      }
    }

    return { telefonos, correos };
  }
}
