import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// modulos globales
import { DataTablesModule } from 'angular-datatables';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';


// pipe
import { MainPipe } from '../pipes/main-pipe.module';
import { SidebarComponent } from './components/sidebar/sidebar.component';

// directivas
import { SIDEBAR_TOGGLE_DIRECTIVES } from 'app/shared/directives/sidebar.directive';
import { CurrencyDirective } from './directives/currency.directive';
import { FocusDirective } from './directives/focus.directive';
import { FileInputValueAccessor } from './directives/observe-files.directive';

// componentes
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { HeaderComponent } from './components/header/header.component';
import { MenuIconsComponent } from './components/menu-icons/menu-icons.component';
import { InicioComponent } from '../pages/menu/inicio.component';
import { CkeditorComponent } from './components/ckeditor/ckeditor.component';
import { FiltroSelectProductoComponent } from './components/filtros/filtro-select-producto/filtro-select-producto.component';
import { FiltroSelectPersonasComponent } from './components/filtros/filtro-select-personas/filtro-select-personas.component';
import { FiltroSelectTipoEmbarquesComponent } from './components/filtros/filtro-select-tipo-embarques/filtro-select-tipo-embarques.component';

import { DetalleDocumentoFullComponent } from './components/detalle-documento-full/detalle-documento-full.component';
import { AnimacionCargandoComponent } from './components/animacion-cargando/animacion-cargando.component';
import { ResumenVentaListadoComponent } from './components/listados/resumen-venta-listado/resumen-venta-listado.component';

import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { ImageCropperEditorComponent } from './components/image-cropper/component/image-cropper-editor/image-cropper-editor.component';
import { FormControlMensajeComponent } from './components/form-control-mensaje/form-control-mensaje.component';
import { MensajeDivComponent } from './components/mensaje-div/mensaje-div.component';
import { EnContruccionComponent } from 'app/pages/en-contruccion/en-contruccion.component';
import { CardMinDashboardComponent } from './components/card-min-dashboard/card-min-dashboard.component';
import { CargandoElementoComponent } from './components/cargando-elemento/cargando-elemento.component';
import { GraficoFormaDePagoComponent } from './components/graficos/grafico-forma-de-pago/grafico-forma-de-pago.component';
import { GraficoTopProductosComponent } from './components/graficos/grafico-top-productos/grafico-top-productos.component';
import { ModalMarcaComponent } from './components/modals/modal-marca/modal-marca.component';
import { ModalProductoComponent } from './components/modals/modal-producto/modal-producto.component';
import { ModalEmpresaComponent } from './components/modals/modal-empresa/modal-empresa.component';
import { ModalCategoriaUnoComponent } from './components/modals/modal-categoria-uno/modal-categoria-uno.component';
import { ModalCategoriaDosComponent } from './components/modals/modal-categoria-dos/modal-categoria-dos.component';
import { ModalCategoriaTresComponent } from './components/modals/modal-categoria-tres/modal-categoria-tres.component';
import { ModalEliminarComponent } from './components/modals/modal-eliminar/modal-eliminar.component';
import { ModalTipoEmbarqueComponent } from './components/modals/modal-tipo-embarque/modal-tipo-embarque.component';

// tslint:disable-next-line: max-line-length
import { ComponenteResumenCierreCajaComponent } from './components/cierre-caja/componente-resumen-cierre-caja/componente-resumen-cierre-caja.component';
import { ConfiguracionVariablesComponent } from '../pages/configuracion-variables/configuracion-variables.component';
import { ModalInformacionComponent } from './components/modals/modal-informacion/modal-informacion.component';
import { ModalDireccionComponent } from './components/modals/modal-direccion/modal-direccion.component';
import { PosDevolucionClienteComponent } from './components/pos-devolucion/pos-devolucion-cliente/pos-devolucion-cliente.component';
import { PosDevolucionListadoComponent } from './components/pos-devolucion/pos-devolucion-listado/pos-devolucion-listado.component';
import { PosDevolucionPagosComponent } from './components/pos-devolucion/pos-devolucion-pagos/pos-devolucion-pagos.component';
import { ListadoNotaDeCreditosComponent } from './components/listados/listado-nota-de-creditos/listado-nota-de-creditos.component';
import { ModalGiroEmpresaComponent } from './components/modals/modal-giro-empresa/modal-giro-empresa.component';
import { ModalPdfComponent } from './components/modals/modal-pdf/modal-pdf.component';
import { ModalComponent } from '../modshared/mod/modal/modal.component';
import { MsgboxComponent } from '../modshared/mod/msgbox/msgbox.component';

// formpas de pagos
import { EfectivoComponent } from './components/formas-de-pago/efectivo/efectivo.component';
import { CreditoComponent } from './components/formas-de-pago/credito/credito.component';
import { TarjetaCreditoComponent } from './components/formas-de-pago/tarjeta-credito/tarjeta-credito.component';
import { TarjetaDebitoComponent } from './components/formas-de-pago/tarjeta-debito/tarjeta-debito.component';
import { PosPagoChequeComponent } from './components/formas-de-pago/pos-pago-cheque/pos-pago-cheque.component';
import { PosPagoChequeFormComponent } from './components/formas-de-pago/pos-pago-cheque-form/pos-pago-cheque-form.component';
import { CardPagosRealizadosComponent } from './components/pagos/card-pagos-realizados/card-pagos-realizados.component';
import { PosChequeVerificadoresComponent } from './components/pos-cheque-verificadores/pos-cheque-verificadores.component';

import { ThermalPrintModule } from 'ng-thermal-print';
import { ImpresionComponent } from './components/impresion/impresion.component';
import { GiroEmpresaComponent } from './components/giro-empresa/giro-empresa.component';
import { DireccionEmpresaComponent } from './components/direccion-empresa/direccion-empresa.component';
import { CobranzaListadoPagosComponent } from './components/listados/cobranza-listado-pagos/cobranza-listado-pagos.component';
import { ModalFormPersonaComponent } from './components/modals/modal-form-persona/modal-form-persona.component';
import { CardFormasPagoComponent } from './components/pagos/card-formas-pago/card-formas-pago.component';
import { CardTipoDocumentosComponent } from './components/pagos/card-tipo-documentos/card-tipo-documentos.component';
import { FormaPagoAgrupadasComponent } from './components/pagos/forma-pago-agrupadas/forma-pago-agrupadas.component';
// tslint:disable-next-line: max-line-length
import { CabeceraResumenCierreCajaComponent } from './components/cierre-caja/cabecera-resumen-cierre-caja/cabecera-resumen-cierre-caja.component';
import { AngularResizedEventModule } from 'angular-resize-event';
import { FiltroSelectBodegaComponent } from './components/filtro-select-bodega/filtro-select-bodega.component';
import { FiltroTipoDocumentosComponent } from './filtro-tipo-documentos/filtro-tipo-documentos.component';
import { FiltrosClientesComponent } from './components/filtros/filtros-clientes/filtros-clientes.component';
import { ContactosEmpresaComponent } from './components/contactos-empresa/contactos-empresa.component';
import { PanelConfiguracionesComponent } from './components/panel/panel-condiguraciones/panel-configuraciones.component';

import { ModalCambiarClaveComponent } from './components/modals/modal-cambiar-clave/modal-cambiar-clave.component';
import { ModalSesionExpiradaComponent } from './components/modals/modal-sesion-expirada/modal-sesion-expirada.component';
import { ModalUsuariosComponent } from './components/modals/modal-usuarios/modal-usuarios.component';

// configuracion tooltip
export function getTooltipConfig(): TooltipConfig {
  return Object.assign(new TooltipConfig(), {
    delay: 300,
  });
}


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DataTablesModule,
    ReactiveFormsModule,
    MainPipe.forRoot(),
    NgSelectModule,
    FormsModule,
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    CKEditorModule,
    ImageCropperModule,
    TooltipModule.forRoot(),
    UiSwitchModule,
    TabsModule.forRoot(),
    BsDropdownModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    PopoverModule.forRoot(),
    NgxMaskModule.forRoot(),
    ThermalPrintModule,
    AngularResizedEventModule,
    ScrollToModule.forRoot()

  ],
  declarations: [
    BreadcrumbComponent,
    HeaderComponent,
    MenuIconsComponent,
    InicioComponent,
    FiltroSelectProductoComponent,
    FiltroSelectPersonasComponent,
    FiltroSelectTipoEmbarquesComponent,
    DetalleDocumentoFullComponent,
    SidebarComponent,
    SIDEBAR_TOGGLE_DIRECTIVES,
    AnimacionCargandoComponent,
    ResumenVentaListadoComponent,
    CkeditorComponent,
    ImageCropperComponent,
    ImageCropperEditorComponent,
    FormControlMensajeComponent,
    MensajeDivComponent,
    EnContruccionComponent,
    CardMinDashboardComponent,
    CargandoElementoComponent,
    GraficoFormaDePagoComponent,
    GraficoTopProductosComponent,
    ModalMarcaComponent,
    ModalProductoComponent,
    ModalEmpresaComponent,
    ModalCategoriaUnoComponent,
    ModalCategoriaDosComponent,
    ModalCategoriaTresComponent,
    ModalEliminarComponent,
    ModalTipoEmbarqueComponent,
    ComponenteResumenCierreCajaComponent,
    ConfiguracionVariablesComponent,
    ModalInformacionComponent,
    ModalDireccionComponent,
    PosDevolucionClienteComponent,
    PosDevolucionListadoComponent,
    PosDevolucionPagosComponent,
    CurrencyDirective,
    FocusDirective,
    ListadoNotaDeCreditosComponent,
    ModalGiroEmpresaComponent,
    ModalPdfComponent,
    ModalComponent,
    MsgboxComponent,
    EfectivoComponent,
    CreditoComponent,
    TarjetaCreditoComponent,
    TarjetaDebitoComponent,
    PosPagoChequeComponent,
    PosPagoChequeFormComponent,
    CardPagosRealizadosComponent,
    PosChequeVerificadoresComponent,
    ImpresionComponent,
    GiroEmpresaComponent,
    DireccionEmpresaComponent,
    CobranzaListadoPagosComponent,
    ModalFormPersonaComponent,
    CardFormasPagoComponent,
    CardTipoDocumentosComponent,
    FormaPagoAgrupadasComponent,
    CabeceraResumenCierreCajaComponent,
    FiltroSelectBodegaComponent,
    FiltroTipoDocumentosComponent,
    FiltrosClientesComponent,
    ContactosEmpresaComponent,
    FileInputValueAccessor,
    PanelConfiguracionesComponent,
    ModalCambiarClaveComponent,
    ModalSesionExpiradaComponent,
    ModalUsuariosComponent
  ],
  exports: [
    PopoverModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    BreadcrumbComponent,
    HeaderComponent,
    MenuIconsComponent,
    DataTablesModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    ModalModule,
    InicioComponent,
    MainPipe,
    FiltroSelectProductoComponent,
    FiltroSelectPersonasComponent,
    FiltroSelectTipoEmbarquesComponent,
    DetalleDocumentoFullComponent,
    SidebarComponent,
    AnimacionCargandoComponent,
    CollapseModule,
    ResumenVentaListadoComponent,
    CkeditorComponent,
    ImageCropperComponent,
    ImageCropperEditorComponent,
    FormControlMensajeComponent,
    MensajeDivComponent,
    EnContruccionComponent,
    CardMinDashboardComponent,
    CargandoElementoComponent,
    GraficoFormaDePagoComponent,
    GraficoTopProductosComponent,
    ModalMarcaComponent,
    ModalProductoComponent,
    ModalEmpresaComponent,
    TooltipModule,
    ModalCategoriaUnoComponent,
    ModalCategoriaDosComponent,
    ModalCategoriaTresComponent,
    ModalEliminarComponent,
    ModalTipoEmbarqueComponent,
    ComponenteResumenCierreCajaComponent,
    ConfiguracionVariablesComponent,
    UiSwitchModule,
    ModalInformacionComponent,
    ModalDireccionComponent,
    PosDevolucionClienteComponent,
    PosDevolucionListadoComponent,
    PosDevolucionPagosComponent,
    CurrencyDirective,
    FocusDirective,
    ListadoNotaDeCreditosComponent,
    ModalGiroEmpresaComponent,
    NgxMaskModule,
    ModalPdfComponent,
    TabsModule,
    BsDropdownModule,
    ModalComponent,
    MsgboxComponent,
    EfectivoComponent,
    CreditoComponent,
    TarjetaCreditoComponent,
    TarjetaDebitoComponent,
    PosPagoChequeComponent,
    PosPagoChequeFormComponent,
    CardPagosRealizadosComponent,
    PosChequeVerificadoresComponent,
    ImpresionComponent,
    ThermalPrintModule,
    DireccionEmpresaComponent,
    GiroEmpresaComponent,
    CobranzaListadoPagosComponent,
    ModalFormPersonaComponent,
    CardFormasPagoComponent,
    CardTipoDocumentosComponent,
    FormaPagoAgrupadasComponent,
    CabeceraResumenCierreCajaComponent,
    AngularResizedEventModule,
    FiltroSelectBodegaComponent,
    FiltroTipoDocumentosComponent,
    FiltrosClientesComponent,
    ContactosEmpresaComponent,
    FileInputValueAccessor,
    PanelConfiguracionesComponent,
    ModalCambiarClaveComponent,
    ModalUsuariosComponent
  ],
  providers: [{ provide: TooltipConfig, useFactory: getTooltipConfig }, BsModalService],
  entryComponents: [ModalSesionExpiradaComponent, ModalUsuariosComponent]
})
export class SharedModule { }