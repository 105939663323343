import { Component, OnInit, Input } from '@angular/core';

import { ConfiguracionesService } from '../../../services/configuraciones.service';
import { ConfiguracionesModel } from '../../../models/configuraciones.model';


@Component({
  selector: 'app-animacion-cargando',
  templateUrl: './animacion-cargando.component.html',
  styleUrls: ['./animacion-cargando.component.scss']
})
export class AnimacionCargandoComponent implements OnInit {

  @Input() cargando = true;
  configuraciones: ConfiguracionesModel;

  constructor(private configuracionesService: ConfiguracionesService) { }

  ngOnInit() {

    this.configuraciones = this.configuracionesService.obtenerVariablesEnMemoria();
    this.configuracionesService.$variables.subscribe(variables => {
      if (variables != null) {
        this.configuraciones = variables;
      }
    });

  }

}
