import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(
    private http: HttpClient
  ) { }
  editarClave(data) {
    return this.http.post(environment.urlApiNew + 'login/cambiar-clave', data);
  }
  cambiarClaveRecuperacion(data) {
    return this.http.post(environment.urlApiNew + 'login/cambiar-clave-recuperacion', data);
  }
  obtenerListado(params) {
    return this.http.get(environment.urlApiNew + 'usuario/listado', { params });
  }
  obtenerUsuario(id) {
    return this.http.get(environment.urlApiNew + `usuario/${id}`);
  }
  obtenerPerfiles(params) {
    return this.http.post(environment.urlApiNew + 'usuario/perfiles', { params });
  }
  guardarUsuario(params) {
    return this.http.post(environment.urlApiNew + 'usuario/guardar', { params });
  }
  editarUsuario(params) {
    return this.http.post(environment.urlApiNew + 'usuario/editar', { params });
  }
}
