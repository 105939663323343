<header class="app-header navbar shadow-sm">
  <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>
    &#9776;
  </button>
  <!-- <a class="navbar-brand" href="#"></a> -->

  <ul class="nav navbar-nav">
    <li class="nav-item d-md-down-none">
      <a class="nav-link navbar-toggler" href="#" appSidebarMinimizer>&#9776;</a>
    </li>


    <li *ngIf="!mostrarMenuOperaciones" class="nav-item px-3">
      <a class="nav-link font-weight-bold">
        <i class="fa fa-home" aria-hidden="true" title="Sucursal"></i> {{sucursal}}
      </a>
    </li>

    <li *ngIf="mostrarMenuOperaciones" class="nav-item dropdown px-3" dropdown
      placement="bottom right">
      <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
        aria-expanded="false" dropdownToggle (click)="(false)">
        <i class="{{operacionesSeleccionada?.icono}} font-weight-bold"></i>
        <span class="font-weight-bold">{{operacionesSeleccionada?.nombre}}</span>
      </a>

      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="simple-dropdown">
        <a *ngFor="let item of opcionesMenu" class="dropdown-item" [ngClass]="{'selected':item.activo}"
          (click)="cambiarOperacion(item)">
          <i class="{{item.icono}}"></i>
          {{item.nombre}}
        </a>
      </div>
    </li>


  </ul>


  <ul class="nav navbar-nav ml-auto nav-derecha  ">

    <li class="nav-item px-3 d-none d-xl-block" *ngIf="detalleCaja">
      <a class="nav-link ">
        <i class="fa fa-desktop" aria-hidden="true"></i>
        <strong>Caja: </strong> {{detalleCaja?.caja_nombre}}
      </a>
    </li>

    <li class="nav-item px-3 d-none d-xl-block" *ngIf="detalleCaja">
      <a class="nav-link ">
        <i class="fa fa-clock-o" aria-hidden="true"></i>
        <strong>Apertura: </strong> {{detalleCaja?.cu_fecha_apertura | date: 'dd-MM-yyyy HH:mm'}}
      </a>
    </li>



    <!-- <li class="nav-item dropdown px-3 animated fadeInt" dropdown placement="bottom right">
      <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
        aria-expanded="false" dropdownToggle (click)="(false)">
        <i class="fa fa-cloud"></i>
        <span class="d-md-down-none">Sincronización</span>
      </a>

      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="simple-dropdown">
        <a class="dropdown-item">
          <i class="fa fa-calendar"></i>
          10-10-2010 16:34
          <em>(Sincronizado)</em>
        </a>
        <a class="dropdown-item">
          <i class="fa fa-refresh"></i>
          Ejecutar sincronización
        </a>
      </div>
    </li> -->


    <li *ngIf="mostrarMenuOperaciones" class="nav-item px-3">
      <a class="nav-link font-weight-bold">
        <i class="fa fa-home" aria-hidden="true" title="Sucursal"></i> {{sucursal}}
      </a>
    </li>

    <li class="nav-item dropdown px-3" dropdown placement="bottom right">
      <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
        aria-expanded="false" dropdownToggle (click)="(false)">
        <i class="icon icon-user"></i>
        <span class="d-md-down-none"> {{ usuarioData.persona?.nombres | titlecase}}</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center">
          <strong>Cuenta</strong>
        </div>
        <div class="dropdown-item">
          <i class="fa fa-user"></i>
          {{ usuarioData.persona?.nombres}} {{ usuarioData.persona?.apellidos}}
          <br>
          <em class="perfil">{{ usuarioData?.perfil}}</em>
        </div>
        
        <a class="dropdown-item" href="#" (click)="abrirConfiguraciones()">
          <i class="fas fa-tools"></i> Configuraciones
        </a>
        
        <a class="dropdown-item" href="#">
          <i class="fa fa-wrench"></i> Cambiar Contraseña</a>
        <div class="divider"></div>
        <a class="dropdown-item" href="javascript:void(0)" (click)="root.logout()">
          <i class="fa fa-lock"></i> Salir</a>
      </div>
    </li>
  </ul>
</header>

<div class="card shadow card-informativo" *ngIf="impresoraConectando">
  <div class="card-body">

    <span *ngIf="impresoraConectando" class=" text-primary">
      <div class="text-center">
        <i class="fa fa-print"></i> 
        <strong class="mr-2">Conectando impresora </strong>
        <div class="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true">
        </div>
      </div>
    </span>
  </div>
</div>