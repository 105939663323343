import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData, CommonModule, TitleCasePipe } from '@angular/common';
import localeCL from '@angular/common/locales/es-CL';
registerLocaleData(localeCL, 'CLP');

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';


import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NAV_DROPDOWN_DIRECTIVES } from './shared/directives/nav-dropdown.directive';
import { AsideToggleDirective } from './shared/directives/aside.directive';
import { DragDropDirective } from './shared/directives/drag-drop.directive';

// Routing Module
import { AppRoutingModule, routes } from './app.routing.module';

// Layouts
import { FullLayoutComponent } from './layouts/full-layout.component';
import { SimpleLayoutComponent } from './layouts/simple-layout.component';
import { PosLayoutComponent } from './layouts/pos-layout.component';

// LocalStorage
import { LocalStorageModule } from 'angular-2-local-storage';

// Services
import { AdonisService } from './adonis.service';
import { DocumentosService } from './services/documentos.service';
import { ToastControlService } from './services/toast-control.service';

// Modulos
import { MyglobalService } from './myglobal.service';
import { AuthGuard } from './shared/security/auth.guard';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { Ng9RutModule } from 'ng9-rut';

import { LoginComponent } from './pages/login/login.component';
import { SharedModule } from './shared/shared.module';
import { RootService } from './services/root.service';
import { ProductosService } from './services/productos.service';
import { NumerosUtils } from './shared/utils/numeros';
import { GenerarPDF } from './shared/utils/generarPDF.util';
// import { CollapseModule } from 'ngx-bootstrap/collapse';

// testing Modulos
import { TestImpresionZebraComponent } from './pages/test-impresion-zebra/test-impresion-zebra.component';
import { LoginRecuperacionComponent } from './pages/login-recuperacion/login-recuperacion.component';

// Interceptors
import { AutenticadorService } from './services/Autenticador.service';

import { SesionService } from './services/sesion.service';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            progressBar: true,
            timeOut: 4000,
            preventDuplicates: true,
            closeButton: true,
        }),
        AppRoutingModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        HttpClientModule,
        LocalStorageModule.forRoot({
            prefix: 'mountainImple',
            storageType: 'localStorage',
        }),
        Ng9RutModule,
        SharedModule,
        // CollapseModule.forRoot(),
    ],
    declarations: [
        AppComponent,
        FullLayoutComponent,
        SimpleLayoutComponent,
        NAV_DROPDOWN_DIRECTIVES,
        AsideToggleDirective,
        DragDropDirective,
        PosLayoutComponent,
        LoginComponent,
        TestImpresionZebraComponent,
        LoginRecuperacionComponent
    ],
    exports: [],
    providers: [
        { provide: LOCALE_ID, useValue: 'CLP' },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
        },
        ToastControlService,
        AdonisService,
        DocumentosService,
        MyglobalService,
        RootService,
        SesionService,
        AuthGuard,
        DatePipe,
        DecimalPipe,
        ProductosService,
        TitleCasePipe,
        NumerosUtils,
        GenerarPDF,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AutenticadorService,
          multi: true
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }