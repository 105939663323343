import { Component, ViewEncapsulation } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { RespuestaApi, RespuestaError } from '../../shared/interfaces/respuesta-api';
import { ToastrService } from 'ngx-toastr';
import { Usuario } from '../../shared/interfaces/usuario';
import { RootService } from '../../services/root.service';
import { DetalleCaja } from '../../shared/interfaces/caja';
import { CajasUsuarioService } from '../../services/cajas-usuario.service';

import { CanalesService } from '../../services/canales.service';
import { ConfiguracionesService } from '../../services/configuraciones.service';
import { mensajes } from '../../data/mensajes';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LoginComponent {

    public usuario: Usuario;
    public usx: any;
    public cargando = false;

    public recuperandoClave = false;
    formLogin: FormGroup;
    formRecuperar: FormGroup;
    constructor(
        public localStore: LocalStorageService,
        public router: Router,
        private formBuilder: FormBuilder,
        private loginService: LoginService,
        private toastr: ToastrService,
        private root: RootService,
        private cajasUsuarioService: CajasUsuarioService,
        private canalesService: CanalesService,
        private configuracionesService: ConfiguracionesService
    ) {


        this.formLogin = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
        });
        this.formRecuperar = this.formBuilder.group({
            email: ['', [
                Validators.required, 
                Validators.email
            ]]
        });

    }

    public login() {
        this.cargando = true;
        this.loginService.iniciarSesion(this.formLogin.value).subscribe((r: RespuestaApi) => {
            this.cargando = false;

            if (r.error) {
                this.toastr.error(r.mensaje, 'Error');
                return false;
            }

            this.usuario = r.data.usuario;
            this.localStore.set('access_token', r.data.access_token);

            // validacion si el usuario tiene modulos
            if (this.usuario.modulos.length === 0) {
                this.toastr.error('El usuario no tiene acceso al sistema', 'Error');
                return;
            }

            this.obtenerVariables();
            
            this.localStore.set('usuario', this.usuario);
            this.localStore.set('sucursal', this.usuario.sucursal);

            this.canalesService.buscar({ nombre: 'Caja' }).subscribe((canales: RespuestaApi) => {
                this.localStore.set('canal', canales.data[0]);
            });

            this.router.navigate(this._construyeRuta());
            this.root.actualizarUsuario(this.usuario);

            if (r.data.usuario.cajasUsuario.length > 0) {
                const detalleCajaAbierta: DetalleCaja = r.data.usuario.detalleCaja;
                this.cajasUsuarioService.guardarSesionCaja(detalleCajaAbierta);
            } else {
                this.cajasUsuarioService.guardarSesionCaja(null);
            }


        }, e => {
            this.cargando = false;
            this.root.validaError(e);
        });
    }

    private obtenerVariables() {
        const parametros = { formato: 'agrupado' };
        this.configuracionesService.obtenerVariables(parametros).subscribe((r: RespuestaApi) => {
            if (r.error) {
                this.toastr.error(r.mensaje, 'Error');
                return false;
            }
            this.configuracionesService.guardarVariablesEnMemoria(r.data);
        }, e => {
            this.cargando = false;
            this.root.validaError(e);
        });
    }

    _construyeRuta() {
        return this.usuario.modulos[0].ruta;
    }
    public recuperarClave(estado: boolean) {
        if (estado && this.formRecuperar.invalid) {
            this.formRecuperar.markAllAsTouched();
            this.toastr.error(mensajes.formularioConErrores, mensajes.tituloError);
            return;
        }
        this.recuperandoClave = !estado;
        if(estado) {
            this.cargando = true;
            console.log(this.cargando);
            this.loginService.recuperarClave(this.formRecuperar.value).subscribe((r: RespuestaApi) => {
                this.cargando = false;
                this.formRecuperar.reset();
                if (r.error) {
                    this.toastr.error(r.mensaje, 'Error');
                    this.recuperandoClave = true;
                    return false;
                }
                this.toastr.success(r.mensaje, 'Revise su correo para continuar');
            }, e => {
                this.cargando = false;
                this.root.validaError(e);
            });
        } else {
            this.formRecuperar.setValue({email: this.formLogin.value.email})
        }
    }

}
