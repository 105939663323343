/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./shared/components/modals/modal-eliminar/modal-eliminar.component.ngfactory";
import * as i3 from "./shared/components/modals/modal-eliminar/modal-eliminar.component";
import * as i4 from "./shared/components/modals/modal-informacion/modal-informacion.component.ngfactory";
import * as i5 from "./shared/components/modals/modal-informacion/modal-informacion.component";
import * as i6 from "./shared/components/panel/panel-condiguraciones/panel-configuraciones.component.ngfactory";
import * as i7 from "./shared/components/panel/panel-condiguraciones/panel-configuraciones.component";
import * as i8 from "./services/impresion.service";
import * as i9 from "./services/root.service";
import * as i10 from "./app.component";
import * as i11 from "./services/notificaciones-ws.service";
import * as i12 from "ngx-bootstrap/modal";
import * as i13 from "./services/configuraciones.service";
import * as i14 from "./services/sesion.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { message: 0 }), (_l()(), i0.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(2, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-modal-eliminar", [], null, null, null, i2.View_ModalEliminarComponent_0, i2.RenderType_ModalEliminarComponent)), i0.ɵdid(4, 114688, null, 0, i3.ModalEliminarComponent, [], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "app-modal-informacion", [], null, [[null, "template"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("template" === en)) {
        var pd_0 = (_co.establecerModalInformacion($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ModalInformacionComponent_0, i4.RenderType_ModalInformacionComponent)), i0.ɵdid(6, 4308992, null, 0, i5.ModalInformacionComponent, [], { dataModal: [0, "dataModal"] }, { template: "template" }), (_l()(), i0.ɵeld(7, 0, null, null, 1, "app-panel-configuraciones", [], null, null, null, i6.View_PanelConfiguracionesComponent_0, i6.RenderType_PanelConfiguracionesComponent)), i0.ɵdid(8, 114688, null, 0, i7.PanelConfiguracionesComponent, [i8.ImpresionService, i9.RootService], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); _ck(_v, 4, 0); var currVal_0 = _co.dataNotificaciones; _ck(_v, 6, 0, currVal_0); _ck(_v, 8, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i10.AppComponent, [i11.NotificacionesWsService, i12.BsModalService, i13.ConfiguracionesService, i9.RootService, i14.SesionService, i8.ImpresionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i10.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
