import { Injectable } from '@angular/core';


import { Observable } from 'rxjs';
import { LocalStorageService } from 'angular-2-local-storage';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { environment } from './../environments/environment';

// Modelos
import { Lineas } from './models/lineas';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable()
export class AdonisService {
    token: string;
    apiruta: string;
    headers: HttpHeaders;

    constructor(private http: HttpClient) {
        // tslint:disable-next-line:max-line-length
        this.token = environment.webToken;
        this.apiruta = environment.urlApi;
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Authorization', this.token);
    }

    // Operaciones API
    public Save(objx, tablename: string): Observable<any> {
        objx = JSON.stringify(objx);
        return this.http
            .post(this.apiruta + tablename, objx, {
                headers: this.headers,
            })
    }
    public Update(objx, tablename: string): Observable<any> {
        const id = objx.id;

        return this.http
            .put(this.apiruta + tablename + '/' + id, objx, {
                headers: this.headers,
            })
    }

    public UpdateCustom(objx, tablename: string, urlstring: string): Observable<any> {
        return this.http.put(this.apiruta + tablename + '/' + urlstring, objx, {
            headers: this.headers,
        })
    }

    public UpdateListaProductos(objx, tablename: string): Observable<any> {
        const id = objx.id;
        let ruta = this.apiruta + tablename + '/' + id + '/true';

        return this.http.put(this.apiruta + tablename + '/' + id + '/true', objx, { headers: this.headers })
    }

    public Update2(id, objx, tablename: string): Observable<any> {
        return this.http.put(this.apiruta + tablename + '/' + id, objx, { headers: this.headers })
    }

    public login(objx, tablename: string): Observable<any> {
        return this.http.post(this.apiruta + tablename, objx, { headers: this.headers })

    }

    public UpdateLogin(ruta: string) {
        return this.http.get(this.apiruta + ruta, { headers: this.headers })

    }

    public Delete(id, tablename: string): Observable<any> {
        return this.http.delete(this.apiruta + tablename + '/' + id, { headers: this.headers })
    }
    public GetObject(id, tablename: string): Observable<any> {
        return this.http
            .get(this.apiruta + tablename + '/' + id, { headers: this.headers })
         
    }
    public GetList(tablename: string): Observable<any> {
        return this.http.get(this.apiruta + tablename, { headers: this.headers })

    }
    public GetListPost(tablename: string, objx): Observable<any> {
        return this.http.post(this.apiruta + tablename, objx, { headers: this.headers })

    }

    public GetQueryObject(tablename: string): Observable<any> {
        return this.http
            .get(this.apiruta + tablename, { headers: this.headers })

    }

    public GetCierre(tablename: string): Observable<any> {
        return this.http.post(this.apiruta + tablename, null, { headers: this.headers })

    }

    public PosCierre(objx, tablename: string): Observable<any> {
        objx = JSON.stringify(objx);
        return this.http.post(this.apiruta + tablename, objx, { headers: this.headers })
    }

    public Post(objx, tablename: string): Observable<any> {
        objx = JSON.stringify(objx);
        return this.http.post(this.apiruta + tablename, objx, { headers: this.headers })

    } // Operaciones API

    private handleJSONResponse(response: Response) {
        try {
            return response.json();
        } catch (e) {
            console.warn('Invalid JSON in response body', response);
            return {};
        }
    }

    // Errores
    private handleErrors(error: Response): any {
        if (error['_body']) {
            const resp = JSON.parse(error['_body']);
            return Observable.throw(resp);
        }

        return Observable.throw(error);
    }
    // Errores
}
