import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Canal } from '../shared/interfaces/canal';
import { RespuestaApi } from 'app/shared/interfaces/respuesta-api';

@Injectable({
  providedIn: 'root'
})
export class CanalesService {

  constructor(
    private http: HttpClient
  ) {
  }

  buscar(query): Observable<RespuestaApi> {
    return this.http.get<RespuestaApi>(environment.urlApiNew + 'canal', {
      params: query
    });
  }
}
